import { useState } from "react";

import PrevIcon from "../../../assets/images/overviewImages/prevIcon.png";
import NextIcon from "../../../assets/images/overviewImages/nextIcon.png";
import infoIcon from "../../../assets/images/overviewImages/infoIcon.png";
import { IconButton } from "@material-ui/core";
import { useStyles } from "./style";
import { productSliderData } from "../../../api";
import Info from "./info";

const ProductSlider = ({ selectedOverviewSlide, onChangeOverviewSlide }) => {
  const classes = useStyles();

  const prevIndex =
    selectedOverviewSlide === 0
      ? productSliderData.length - 1
      : selectedOverviewSlide - 1;

  const nextIndex =
    selectedOverviewSlide === productSliderData.length - 1
      ? 0
      : selectedOverviewSlide + 1;

  const prevImg = productSliderData[prevIndex].image;
  const currentImg = productSliderData[selectedOverviewSlide].image;
  const nextImg = productSliderData[nextIndex].image;

  const prevLbl = productSliderData[prevIndex].label;
  const currentLbl = productSliderData[selectedOverviewSlide].label;
  const nextLbl = productSliderData[nextIndex].label;

  const currentInfo = productSliderData[selectedOverviewSlide].info;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const onClickPrev = () => {
    onChangeOverviewSlide(prevIndex);
  };

  const onClickNext = () => {
    onChangeOverviewSlide(nextIndex);
  };
  //info box

  const handleClick = (event) => {
    setShowInfo(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowInfo(false);
  };
  return (
    <div className={classes.rootContainer}>
      <div className={classes.mainSliderContainer}>
        <div className={classes.prevIcon} onClick={onClickPrev}>
          <img src={PrevIcon} alt={"Previous Icon"} />
        </div>
        <div className={classes.sliderContainer}>
          <div className={classes.slide}>
            <img src={prevImg} className={classes.productImage} alt={prevLbl} />
            <div className={classes.title}>{prevLbl}</div>
          </div>
          <div className={classes.slide}>
            <img
              src={currentImg}
              className={classes.selectedProductImage}
              alt={currentLbl}
            />
            <div className={classes.selectedTitle}>{currentLbl}</div>
          </div>
          <div className={classes.slide}>
            <img src={nextImg} className={classes.productImage} alt={prevLbl} />
            <div className={classes.title}>{nextLbl}</div>
          </div>
        </div>
        <div className={classes.nextIcon} onClick={onClickNext}>
          <img src={NextIcon} alt={"Next Icon"} />
        </div>
      </div>
      <div className={classes.currentInfo}>
        <IconButton className={classes.InfoIcon} onClick={handleClick}>
          <img src={infoIcon} className={classes.imgIcon} alt="Info" />
        </IconButton>
      </div>
      {showInfo && (
        <Info
          handleClose={handleClose}
          handleClick={handleClick}
          open={showInfo}
          infoText={currentInfo}
          anchorEl={anchorEl}
        />
      )}
    </div>
  );
};

export default ProductSlider;
