import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TABLET } from "../../config/styleConfig";

import Tooltip from "@material-ui/core/Tooltip";

const ColorTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 15,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  selectedImageBox: {
    height: "38px",
    width: "38px",
    marginRight: "1%",
    transform: "scale(1.4)",
    [theme.breakpoints.down(TABLET)]: {
      height: "43px",
      width: "43px",
      transform: "scale(1.3)",
    },
  },
  imageBox: {
    height: "38px",
    width: "38px",
    marginRight: "1%",
    [theme.breakpoints.down(TABLET)]: {
      height: "40px",
      width: "40px",
    },
  },
  colorsImage: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
  },
  tooltip: {
    fontSize: 100,
  },
}));

const WorktopColorImages = ({ data, onSelect, selectedWorktop }) => {
  const classes = useStyles();

  const checkSelected = (indx) => {
    return indx === selectedWorktop;
  };

  return (
    <>
      {data &&
        data.map((val, indx) => (
          <div
            className={
              checkSelected(val.UUID)
                ? classes.selectedImageBox
                : classes.imageBox
            }
            onClick={() => onSelect(val.UUID)}
            key={`${indx}`}
          >
            {val.title ? (
              <ColorTooltip title={val.title} placement="top" arrow>
                <img
                  src={val.image}
                  alt={val.title}
                  className={classes.colorsImage}
                  key={val.UUID}
                />
              </ColorTooltip>
            ) : (
              <img
                src={val.image}
                alt={val.UUID}
                className={classes.colorsImage}
                key={val.UUID}
              />
            )}
          </div>
        ))}
    </>
  );
};

export default WorktopColorImages;
