import { makeStyles } from "@material-ui/core/styles";
import { TABLET, MID_SCREEN } from "../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 20,
    [theme.breakpoints.down(TABLET)]: {
      padding: 5,
    },
  },
  leftContainer: {
    padding: "10px 30px",
    [theme.breakpoints.down(TABLET)]: {
      marginBottom: 50,
      padding: 10,
      display: "none",
    },
  },
  rightContent: {
    padding: "20px",
  },
  imgContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    maxHeight: 340,
  },
  imgResponsive: {
    maxWidth: "100%",
  },
  chooseDeviceTitle: {
    fontWeight: 700,
    fontSize: 25,
    textAlign: "center",
    marginBottom: 20,
  },
  checkboxContainer: {
    marginTop: 30,
  },
  newsletterCheckbox: {
    display: "flex",
  },
  rightContainer: {
    padding: "10px 30px",
    display: "flex",
    flex: 1,
    [theme.breakpoints.down(TABLET)]: {
      display: "none",
    },
  },
  mobileRightContainer: {
    padding: 20,
    [theme.breakpoints.up(MID_SCREEN)]: {
      display: "none",
    },
  },
  firstInfoText: {
    fontSize: 16,
    fontWeight: 400,
    color: "#383838",
    lineHeight: 1.6,
  },
  convinceTitle: {
    marginTop: 20,
    fontSize: 25,
    color: "#383838",
    fontWeight: 700,
    [theme.breakpoints.down(TABLET)]: {
      marginTop: 20,
      fontSize: 14,
      fontWeight: 700,
      textAlign: "center",
    },
  },
  receiveMailText: {
    marginTop: 20,
    fontSize: 16,
    color: "#383838",
    fontWeight: 700,
    maxWidth: "35%",
    [theme.breakpoints.down(TABLET)]: {
      maxWidth: "unset",
      textAlign: "center",
      fontSize: 14,
      fontWeight: 700,
    },
  },
  emailButton: {
    marginTop: 25,
    [theme.breakpoints.down(TABLET)]: {
      width: "100%",
    },
  },
  angreifenContainer: {
    maxWidth: "60%",
    [theme.breakpoints.down(TABLET)]: {
      maxWidth: "unset",
    },
  },
  angreifenInfo: {
    marginTop: 20,
    color: "#383838",
    lineHeight: 1.4,
    [theme.breakpoints.down(TABLET)]: {
      fontSize: 14,
    },
  },
  formContainer: {
    marginTop: 30,
    justifyContent: "space-between",
    [theme.breakpoints.down(TABLET)]: {},
  },
  anfordernBtn: {
    width: "100%",
    [theme.breakpoints.down(TABLET)]: {
      marginTop: 20,
      width: "95%",
    },
  },
  formInput: {
    marginTop: 10,
    width: "80%",
    [theme.breakpoints.down(TABLET)]: {
      marginTop: 20,
      width: "90%",
    },
  },
  formRight: {
    display: "flex",
    flexDirection: "column",
  },
  imagePickerContainer: {
    marginTop: 25,
    [theme.breakpoints.down(TABLET)]: {},
  },

  mobileHeader: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 30,
  },
  error: {
    color: "red",
  },
}));
