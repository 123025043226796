// Small devices (landscape phones, 576px and up)
export const MOBILE_SCREEN = "xs";
// Devices with ≥576px
export const TABLET = "sm";
// Medium devices (tablets, 768px and up)
export const MID_SCREEN = "md";
// Large devices (desktops, 992px and up)
export const LARGE_SCREEN = "lg";
// Extra large devices (large desktops, 1200px and up)
export const XTRA_LARGE_SCREEN = "xl";
