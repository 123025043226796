import { makeStyles } from "@material-ui/core/styles";
import { MOBILE_SCREEN, TABLET } from "../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  webBackButtonContainer: {
    display: "block",
    [theme.breakpoints.down(TABLET)]: {
      display: "none",
    },
  },
  mobileBackButtonContainer: {
    display: "none",
    [theme.breakpoints.down(TABLET)]: {
      display: "block",
      marginLeft: -25,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "right",
  },
  backIcon: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      display: "block",
      justifyContent: "flex-end",
    },
  },
}));
