import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const MaterialWrapper = ({ children }) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: grey[50],
      },
      secondary: {
        main: grey[900],
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialWrapper;
