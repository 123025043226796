import axios from "axios";
import { BASE_URL } from "../config";

axios.defaults.baseURL = BASE_URL;

export const getMaterialsColorsData = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/material/data/?format=json")
      .then((response) => {
        resolve({
          success: true,
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          success: false,
          message: "Something went wrong",
        });
      });
  });
};

export const submitSampleFreeOfCharge = (submitData) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/muster-order/create/", submitData)
      .then((response) => {
        resolve({
          success: true,
        });
      })
      .catch((error) => {
        console.log("error : ", error);
        resolve({
          success: false,
          message: "Something went wrong",
        });
      });
  });
};

export const submit3dOrderData = (submitData) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/3d-order/create/", submitData)
      .then((response) => {
        resolve({
          success: true,
        });
      })
      .catch((error) => {
        console.log("error : ", error);
        resolve({
          success: false,
          message: "Something went wrong",
        });
      });
  });
};
