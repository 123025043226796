import { useContext } from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./style";
import HeaderDot from "../../components/HeaderDot";
import { ChooseShapeContext, constant } from "../../context/ChooseShape";
import ProductSlider from "../../components/UI/ProductSlider";
import MobileProductSlider from "../../components/UI/MobileProductSlider";
import Checkbox from "../../components/UI/Checkbox";
import Button from "../../components/UI/Button";
import KitchenImage from "../../components/KitchenImage";

import MobileRight from "./mobileRight";

const Left = ({
  selectedOverviewSlide,
  onChangeOverviewSlide,
  selectedKitchenShape,
  selectedColor,
  kitchenLengthValue,
  onSubmit,
  apiData,
  useOwnDevice,
  setUseOwnDevice,
  handleBookAppointment,
}) => {
  const classes = useStyles();

  const { config, dispatch } = useContext(ChooseShapeContext);

  const onClickNext = () => {
    dispatch({
      [constant.DOTS]: config[constant.DOTS] + 1,
    });
  };

  const display1 = config[constant.DOTS] === 4 ? "block" : "none";
  const display2 = config[constant.DOTS] === 5 ? "block" : "none";

  return (
    <Grid xs={12} md={4} className={classes.leftContainer}>
      <div>
        <HeaderDot value={config[constant.DOTS]} />
        <KitchenImage
          src={selectedKitchenShape.image}
          text={`${selectedKitchenShape.type}`}
        />
        <div style={{ display: display1 }}>
          <div className={classes.chooseDeviceTitle}>Wähle ein Gerätepaket</div>
          <ProductSlider
            onChangeOverviewSlide={onChangeOverviewSlide}
            selectedOverviewSlide={selectedOverviewSlide}
          />
          <MobileProductSlider
            onChangeOverviewSlide={onChangeOverviewSlide}
            selectedOverviewSlide={selectedOverviewSlide}
          />
          <div className={classes.checkboxContainer}>
            <Checkbox
              label="Ich verwende meine eigenen Geräte: "
              onChange={() => setUseOwnDevice(!useOwnDevice)}
              active={useOwnDevice}
            />
          </div>
          <div className={classes.mobileSubmitButtonContainer}>
            <Button active onClick={onClickNext}>
              <div>WEITER</div>
            </Button>
          </div>
        </div>
      </div>
      <div style={{ display: display2 }}>
        <MobileRight
          onSubmit={onSubmit}
          selectedColor={selectedColor}
          selectedKitchenShape={selectedKitchenShape}
          kitchenLengthValue={kitchenLengthValue}
          useOwnDevice={useOwnDevice}
          apiData={apiData}
          selectedOverviewSlide={selectedOverviewSlide}
          handleBookAppointment={handleBookAppointment}
        />
      </div>
    </Grid>
  );
};

export default Left;
