import { makeStyles } from "@material-ui/core/styles";
import { MID_SCREEN, TABLET } from "../../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: "none",
    [theme.breakpoints.down(TABLET)]: {
      display: "block",
    },
  },
  mainSliderContainer: {
    [theme.breakpoints.down(TABLET)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  prevIcon: {
    transform: "translateY(-45px)",
    [theme.breakpoints.only(MID_SCREEN)]: {
      transform: "translateY(-15px)",
    },
  },
  nextIcon: {
    transform: "translateY(-45px)",
    [theme.breakpoints.only(MID_SCREEN)]: {
      transform: "translateY(-15px)",
    },
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 15,
    flex: 1,
    justifyContent: "space-between",
  },
  slide: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    padding: "25px 0",
  },
  selectedSlide: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    backgroundColor: "#D3CDC3",
    padding: "25px 0",
    borderRadius: 20,
  },
  title: {
    fontSize: 16,
    marginTop: 5,
  },
  selectedTitle: {
    fontSize: 16,
    marginTop: 5,
  },
  currentInfo: {
    maxWidth: "50%",
    margin: "auto",
    textAlign: "center",
    marginTop: 30,
  },
  productImage: {
    width: "40%",
  },
  selectedProductImage: {
    width: "40%",
  },
  infoIcon: {
    marginTop: 5,
  },
  infoContainer: {
    position: "fixed",
    backgroundColor: "#FFF",
    left: 0,
    right: 0,
    bottom: 0,
    top: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 50,
    zIndex: 1,
  },
  activeImageInfo: {
    marginTop: 20,
    objectFit: "contain",
    maxHeight: 120,
  },
  activeLabelInfo: {
    marginTop: 30,
    textAlign: "center",
    color: "#383838",
  },
  closeArrowButton: {
    marginTop: 20,
  },
  closeArrowContainer: {
    padding: 10,
  },
}));
