import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FileIcon from "../../../assets/images/layouts/mobileFilePicker.png";
import SelectedFile from "../../../assets/images/layouts/selectedFile.png";

const useStyles = makeStyles((theme) => ({
  picker: {
    margin: "0 4%",
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  in: {
    position: "absolute",
    opacity: "0",
    margin: "1% -5%",
    padding: "1%",
  },
  img: {
    cursor: "pointer",
  },
  lbl: {
    fontWeight: "400",
    marginTop: 10,
    color: "#383838",
    fontSize: 20,
  },
  selectedFile: {
    position: "absolute",
    height: 40,
    top: 30,
  },
}));

const ImagePicker = ({ onChange, selected = false, icon = null }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.picker}>
        <input type="file" onChange={onChange} className={classes.in} />
        <img
          src={icon === null ? FileIcon : icon}
          alt="Shapes"
          className={classes.img}
        />
        {selected && (
          <div>
            <img
              className={classes.selectedFile}
              alt="Shapes"
              src={SelectedFile}
            />
          </div>
        )}
        <label className={classes.lbl}>Datei wählen</label>
      </div>
    </>
  );
};

export default ImagePicker;
