import { Grid } from "@material-ui/core";
import Web from "./web";
import Mobile from "./mobile";
import { useStyles } from "./style";

const ColorSelector = ({ ...propsData }) => {
  const classes = useStyles();
  return (
    <Grid xs={12} md={8} className={classes.rootColorSelector}>
      <Web {...propsData} />
      <Mobile {...propsData} />
    </Grid>
  );
};

export default ColorSelector;
