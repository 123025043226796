export const BackIcon = () => {
  return (
    <svg
      width="7"
      height="15"
      viewBox="0 0 7 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83749 14.0013C5.68809 14.0018 5.54048 13.9688 5.4055 13.9048C5.27052 13.8407 5.15161 13.7473 5.05749 13.6313L0.227488 7.63125C0.0804062 7.45232 0 7.22788 0 6.99625C0 6.76463 0.0804062 6.54018 0.227488 6.36125L5.22749 0.361252C5.39723 0.157036 5.64114 0.0286112 5.90556 0.0042315C6.16999 -0.0201482 6.43327 0.0615137 6.63749 0.231252C6.8417 0.400991 6.97013 0.644902 6.99451 0.909329C7.01889 1.17375 6.93723 1.43704 6.76749 1.64125L2.29749 7.00125L6.61749 12.3613C6.73977 12.508 6.81745 12.6868 6.84133 12.8763C6.86521 13.0659 6.83429 13.2583 6.75223 13.4308C6.67018 13.6034 6.54042 13.7488 6.37831 13.8499C6.2162 13.9509 6.02852 14.0035 5.83749 14.0013Z"
        fill="#383838"
      />
    </svg>
  );
};

export const InactiveDot = () => {
  return (
    <svg
      width="5"
      height="5"
      viewBox="0 0 5 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill="#D3CDC3" />
    </svg>
  );
};

export const ActiveDot = () => {
  return (
    <svg
      width="5"
      height="5"
      viewBox="0 0 5 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill="#383838" />
    </svg>
  );
};

export const LOGO = () => {
  return (
    <svg
      width="81"
      height="34"
      viewBox="0 0 81 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.34184 28.2052L1.49235 32.6551H0.84949L0 28.2052H0.665816L1.14796 31.2645C1.14796 31.3572 1.14796 31.4731 1.17092 31.6353C1.17092 31.5426 1.17092 31.4267 1.19388 31.2645L1.69898 28.2052H2.34184Z"
        fill="#2E3438"
      />
      <path
        d="M6.08415 32.2386C6.08415 32.5167 5.94639 32.6557 5.69384 32.6557H4.27037C4.01782 32.6557 3.88007 32.5167 3.88007 32.2386V28.5999C3.88007 28.3217 4.01782 28.1827 4.27037 28.1827H5.69384C5.96935 28.1827 6.08415 28.3217 6.08415 28.5999V32.2386ZM5.41833 32.0763V28.7621H4.52292V32.0763H5.41833Z"
        fill="#2E3438"
      />
      <path
        d="M10.4235 32.6557H9.78067V30.454C9.78067 30.3381 9.80363 30.1759 9.82659 29.9673L9.25261 32.2386H8.90822L8.33424 29.9673C8.3572 30.1759 8.38016 30.3381 8.38016 30.454V32.6557H7.7373V28.2058H8.3572L9.04598 30.6626C9.04598 30.6857 9.06894 30.7553 9.06894 30.8711C9.06894 30.8248 9.06894 30.7553 9.0919 30.6626L9.78067 28.2058H10.4006V32.6557H10.4235Z"
        fill="#2E3438"
      />
      <path
        d="M16.3699 28.7853H15.6352V32.6326H14.9694V28.7853H14.2347V28.2058H16.3469V28.7853H16.3699Z"
        fill="#2E3438"
      />
      <path
        d="M18.528 32.6557H17.8622V28.2058H18.528V32.6557Z"
        fill="#2E3438"
      />
      <path
        d="M22.3163 32.2386C22.3163 32.5167 22.1786 32.6557 21.926 32.6557H20.5944C20.3189 32.6557 20.2041 32.5167 20.2041 32.2386V31.1724H20.8699V32.0763H21.6735V31.1956L20.3648 29.9441C20.273 29.8514 20.2271 29.7355 20.2271 29.5964V28.5999C20.2271 28.3217 20.3648 28.1827 20.6174 28.1827H21.949C22.2245 28.1827 22.3393 28.3217 22.3393 28.5767V29.5733H21.6735V28.7389H20.8699V29.5501L22.1786 30.8016C22.2704 30.8943 22.3163 31.0102 22.3163 31.1724V32.2386Z"
        fill="#2E3438"
      />
      <path
        d="M26.1735 32.2386C26.1735 32.5167 26.0357 32.6557 25.7602 32.6557H24.3827C24.1301 32.6557 23.9924 32.5167 23.9924 32.2386V28.5999C23.9924 28.3217 24.1301 28.1827 24.3827 28.1827H25.7602C26.0357 28.1827 26.1735 28.3217 26.1735 28.5999V29.6428H25.5077V28.7621H24.6582V32.0763H25.5077V31.1493H26.1735V32.2386Z"
        fill="#2E3438"
      />
      <path
        d="M30.0536 32.6557H29.3878V30.6626H28.4924V32.6557H27.8265V28.2058H28.4924V30.0368H29.3878V28.2058H30.0536V32.6557Z"
        fill="#2E3438"
      />
      <path
        d="M33.4515 32.6557H31.7296V28.2058H32.3954V32.0532H33.4745V32.6557H33.4515Z"
        fill="#2E3438"
      />
      <path
        d="M36.8265 32.6557H34.9897V28.2058H36.8035V28.7853H35.6556V30.0831H36.6428V30.6626H35.6556V32.0532H36.8265V32.6557Z"
        fill="#2E3438"
      />
      <path
        d="M40.6837 32.6557H39.9949L39.2602 30.593V30.199H39.9031V28.7853H39.0765V32.6557H38.4107V28.2058H40.1556C40.4311 28.2058 40.5459 28.3449 40.5459 28.623V30.2454C40.5459 30.4308 40.477 30.5467 40.3622 30.6162C40.2934 30.6394 40.1556 30.6626 39.926 30.6626L40.6837 32.6557Z"
        fill="#2E3438"
      />
      <path
        d="M78.75 11.8664H76.5459V2.24813H45.4133V9.17791H43.2092V0H78.75V11.8664Z"
        fill="#B1B8BA"
      />
      <path
        d="M78.75 34H43.2092V25.726H45.4133V31.775H76.5459V25.726H78.75V34Z"
        fill="#B1B8BA"
      />
      <path
        d="M7.14036 24.1036H4.77556V23.7328C3.16842 24.0109 2.27301 24.1731 2.06638 24.1731C1.46944 24.1731 1.01026 23.9877 0.665869 23.6401C0.321481 23.2924 0.160767 22.8057 0.160767 22.2031V15.6442C0.160767 14.7866 0.459236 14.184 1.07913 13.8596C1.51536 13.6046 2.20413 13.4887 3.09954 13.4887C3.30617 13.4887 3.58168 13.4887 3.97199 13.5119C4.3623 13.5351 4.61485 13.5351 4.77556 13.5351V7.69461H7.14036V24.1036ZM4.77556 22.0409V15.5283H3.30617C2.77811 15.5283 2.52556 15.7832 2.52556 16.3163V21.392C2.52556 21.9018 2.77811 22.1568 3.28322 22.1568C3.37505 22.1568 3.88015 22.1104 4.77556 22.0409Z"
        fill="#2E3438"
      />
      <path
        d="M13.7985 12.0055H11.4108V9.52557H13.7985V12.0055ZM13.7756 24.1036H11.4337V13.5119H13.7756V24.1036Z"
        fill="#2E3438"
      />
      <path
        d="M24.8419 22.8057C24.8419 23.6864 24.4057 24.1268 23.5102 24.1268H19.4005C18.5051 24.1268 18.0689 23.6864 18.0689 22.8057V14.833C18.0689 13.9523 18.5051 13.5119 19.4005 13.5119H23.5102C24.4057 13.5119 24.8419 13.9523 24.8419 14.833V18.7266L24.1761 19.3987H20.3419V22.3653H22.5689V20.6039H24.8419V22.8057ZM22.5689 17.8227V15.2733H20.3419V17.8227H22.5689Z"
        fill="#2E3438"
      />
      <path
        d="M37.4006 24.1036H34.7144L31.6378 16.7798V24.1036H29.2042V7.69461H31.6378V14.6476L34.347 7.69461H37.0103V7.81049L33.7042 15.5978L37.4006 24.1036Z"
        fill="#2E3438"
      />
      <path
        d="M47.7551 24.1036H45.3904V23.7328C44.8393 23.8255 44.2883 23.9182 43.7373 24.0109C43.0715 24.1268 42.5205 24.1731 42.0842 24.1731C41.2118 24.1731 40.7756 23.7328 40.7756 22.8521V13.5119H43.1404V22.1104L45.3904 22.0641V13.5119H47.7551V24.1036ZM43.8291 12.8166H41.5562V10.5685H43.8291V12.8166ZM46.9975 12.8166H44.7245V10.5685H46.9975V12.8166Z"
        fill="#2E3438"
      />
      <path
        d="M58.7755 22.8057C58.7755 23.6864 58.3393 24.1268 57.4669 24.1268H53.3342C52.4388 24.1268 52.0026 23.6864 52.0026 22.8057V14.833C52.0026 13.9523 52.4388 13.5119 53.3342 13.5119H57.4669C58.3393 13.5119 58.7755 13.9523 58.7755 14.833V17.3592H56.4566V15.5283H54.3214V22.1336H56.4566V20.1172H58.7755V22.8057Z"
        fill="#2E3438"
      />
      <path
        d="M69.9797 24.1036H67.5919V15.5283L65.3649 15.5746V24.1036H62.9771V7.69461H65.3649V13.9059C65.9159 13.8132 66.4669 13.7437 67.0179 13.651C67.7067 13.5351 68.2807 13.4887 68.671 13.4887C69.5434 13.4887 69.9797 13.9291 69.9797 14.7866V24.1036Z"
        fill="#2E3438"
      />
      <path
        d="M81 22.8057C81 23.6864 80.5638 24.1268 79.6684 24.1268H75.5357C74.6403 24.1268 74.2041 23.6864 74.2041 22.8057V14.833C74.2041 13.9523 74.6403 13.5119 75.5357 13.5119H79.6454C80.5408 13.5119 80.9771 13.9523 80.9771 14.833V18.7266L80.3112 19.3987H76.4541V22.3653H78.6811V20.6039H80.9541V22.8057H81ZM78.7271 17.8227V15.2733H76.5V17.8227H78.7271Z"
        fill="#2E3438"
      />
    </svg>
  );
};

export const MenuIcon = () => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.7619C0 1.29462 0.185629 0.846471 0.51605 0.51605C0.846471 0.185629 1.29462 0 1.7619 0H22.9048C23.372 0 23.8202 0.185629 24.1506 0.51605C24.481 0.846471 24.6667 1.29462 24.6667 1.7619C24.6667 2.22919 24.481 2.67734 24.1506 3.00776C23.8202 3.33818 23.372 3.52381 22.9048 3.52381H1.7619C1.29462 3.52381 0.846471 3.33818 0.51605 3.00776C0.185629 2.67734 0 2.22919 0 1.7619ZM0 10.5714C0 10.1041 0.185629 9.656 0.51605 9.32557C0.846471 8.99515 1.29462 8.80952 1.7619 8.80952H22.9048C23.372 8.80952 23.8202 8.99515 24.1506 9.32557C24.481 9.656 24.6667 10.1041 24.6667 10.5714C24.6667 11.0387 24.481 11.4869 24.1506 11.8173C23.8202 12.1477 23.372 12.3333 22.9048 12.3333H1.7619C1.29462 12.3333 0.846471 12.1477 0.51605 11.8173C0.185629 11.4869 0 11.0387 0 10.5714ZM0 19.381C0 18.9137 0.185629 18.4655 0.51605 18.1351C0.846471 17.8047 1.29462 17.619 1.7619 17.619H22.9048C23.372 17.619 23.8202 17.8047 24.1506 18.1351C24.481 18.4655 24.6667 18.9137 24.6667 19.381C24.6667 19.8482 24.481 20.2964 24.1506 20.6268C23.8202 20.9572 23.372 21.1429 22.9048 21.1429H1.7619C1.29462 21.1429 0.846471 20.9572 0.51605 20.6268C0.185629 20.2964 0 19.8482 0 19.381Z"
        fill="#383838"
      />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 51 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26.1406" cy="22.1406" r="14.1093" fill="#383838" />
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4728 4C23.1783 4 20.9062 4.46558 18.7863 5.37017C16.6664 6.27475 14.7402 7.60062 13.1177 9.27208C11.4952 10.9435 10.2081 12.9278 9.33004 15.1117C8.45195 17.2956 8 19.6362 8 22C8 24.3638 8.45195 26.7044 9.33004 28.8883C10.2081 31.0722 11.4952 33.0565 13.1177 34.7279C14.7402 36.3994 16.6664 37.7252 18.7863 38.6298C20.9062 39.5344 23.1783 40 25.4728 40C30.1069 40.0003 34.5513 38.1042 37.8283 34.7287C41.1053 31.3533 42.9464 26.775 42.9467 22.0011C42.947 17.2272 41.1064 12.6487 37.8298 9.27288C34.5533 5.89701 30.1091 4.0003 25.475 4H25.4728ZM23.3399 10.8027H27.7422V15.3379H23.3399V10.8027ZM27.7422 30.678C27.7422 32.2245 26.7605 33.4785 25.5411 33.4785C24.3238 33.4785 23.3399 32.2268 23.3399 30.678C23.3399 26.6665 23.3399 24.4174 23.3399 20.4059C23.3399 18.8639 24.3238 17.6054 25.5411 17.6054C26.7605 17.6054 27.7422 18.8594 27.7422 20.4059V30.678Z"
          fill="#D3CDC3"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="50.9467"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M22.7431 16.9167L26.3972 13.2638C26.5172 13.1438 26.6124 13.0014 26.6774 12.8446C26.7423 12.6878 26.7757 12.5197 26.7757 12.35C26.7757 12.1803 26.7423 12.0122 26.6774 11.8554C26.6124 11.6986 26.5172 11.5561 26.3972 11.4361C26.2772 11.3161 26.1347 11.2209 25.9779 11.156C25.8211 11.091 25.6531 11.0576 25.4834 11.0576C25.3136 11.0576 25.1456 11.091 24.9888 11.156C24.832 11.2209 24.6895 11.3161 24.5695 11.4361L20.9167 15.0903L17.2638 11.4361C17.0215 11.1938 16.6927 11.0576 16.35 11.0576C16.0072 11.0576 15.6785 11.1938 15.4361 11.4361C15.1938 11.6785 15.0576 12.0072 15.0576 12.35C15.0576 12.5197 15.091 12.6878 15.156 12.8446C15.2209 13.0014 15.3161 13.1438 15.4361 13.2638L19.0903 16.9167L15.4361 20.5695C15.1938 20.8119 15.0576 21.1406 15.0576 21.4834C15.0576 21.8261 15.1938 22.1548 15.4361 22.3972C15.6785 22.6396 16.0072 22.7757 16.35 22.7757C16.6927 22.7757 17.0215 22.6396 17.2638 22.3972L20.9167 18.7431L24.5695 22.3972C24.8119 22.6396 25.1406 22.7757 25.4834 22.7757C25.8261 22.7757 26.1548 22.6396 26.3972 22.3972C26.6396 22.1548 26.7757 21.8261 26.7757 21.4834C26.7757 21.1406 26.6396 20.8119 26.3972 20.5695L22.7431 16.9167ZM20.9167 29.8333C13.7828 29.8333 8 24.0505 8 16.9167C8 9.78279 13.7828 4 20.9167 4C28.0505 4 33.8333 9.78279 33.8333 16.9167C33.8333 24.0505 28.0505 29.8333 20.9167 29.8333Z"
          fill="#383838"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="41.8333"
          height="41.8333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const CloseArrowIcon = () => {
  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9999 2.157C20.0006 2.4345 19.9535 2.70868 19.8621 2.9594C19.7706 3.21012 19.6371 3.43101 19.4714 3.60583L10.9008 12.5774C10.6452 12.8506 10.3246 13 9.9937 13C9.66284 13 9.34224 12.8506 9.08665 12.5774L0.516026 3.29006C0.224315 2.97477 0.040869 2.52172 0.00604405 2.03055C-0.0287809 1.53938 0.087868 1.05035 0.330329 0.671018C0.572789 0.29169 0.921201 0.0531454 1.29892 0.00786011C1.67663 -0.0374242 2.05271 0.11426 2.34442 0.429545L10.0008 8.73247L17.6573 0.708167C17.8669 0.481029 18.1223 0.336745 18.393 0.29239C18.6638 0.248034 18.9386 0.305462 19.1851 0.457878C19.4315 0.610295 19.6392 0.851321 19.7836 1.15244C19.928 1.45355 20.0031 1.80216 19.9999 2.157Z"
        fill="#383838"
      />
    </svg>
  );
};

export const EuroSign = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6608 18.9444C13.2537 18.9458 11.8734 18.5474 10.6709 17.7929C9.46835 17.0384 8.49001 15.9569 7.84291 14.6667H13.927C14.3768 14.6667 14.791 14.41 14.9804 13.9944C15.371 13.1878 14.8029 12.2222 13.927 12.2222H7.06169C7.00251 11.8189 6.967 11.4156 6.967 11C6.967 10.5844 7.00251 10.1811 7.06169 9.77778H13.927C14.3768 9.77778 14.791 9.52111 14.9804 9.10556C15.0733 8.91974 15.1182 8.7123 15.1108 8.50332C15.1033 8.29434 15.0437 8.0909 14.9377 7.91267C14.8318 7.73444 14.6831 7.58747 14.506 7.48598C14.3289 7.38448 14.1295 7.33191 13.927 7.33333H7.84291C8.49116 6.04411 9.46976 4.96342 10.672 4.20908C11.8743 3.45475 13.254 3.05574 14.6608 3.05556C16.1404 3.05556 17.5253 3.49556 18.709 4.24111C19.3008 4.62 20.0702 4.55889 20.5673 4.04556C21.2539 3.33667 21.1 2.17556 20.2714 1.65C18.5855 0.57369 16.643 0.00243006 14.6608 0C10.0209 0 6.09108 3.06778 4.62333 7.33333H1.1907C0.740903 7.33333 0.326619 7.59 0.125395 8.00556C-0.265215 8.82444 0.302946 9.77778 1.1907 9.77778H4.07885C3.98298 10.5896 3.98298 11.4104 4.07885 12.2222H1.1907C0.740903 12.2222 0.326619 12.4789 0.137232 12.8944C-0.265215 13.7133 0.302946 14.6667 1.1907 14.6667H4.62333C6.09108 18.9322 10.0209 22 14.6608 22C16.7204 22 18.638 21.4011 20.2714 20.35C21.0882 19.8244 21.242 18.6511 20.5555 17.9422C20.0584 17.4289 19.289 17.3678 18.6971 17.7589C17.5253 18.5167 16.1523 18.9444 14.6608 18.9444Z"
        fill="#2F80ED"
      />
    </svg>
  );
};
