import React, { useState, useEffect, useContext } from "react";
import { useStyles } from "./style";
import { Grid } from "@material-ui/core";
import { ChooseShapeContext, constant } from "../../../context/ChooseShape";
import MobileFrontColorSelect from "../../../components/FrontColorSelect/mobile";
import MobileBodyColorSelect from "../../../components/BodyColorSelect/mobile";
import BodyColorImages from "../../../components/BodyColorSelect/BodyColorImages";
import WorktopColorSelect from "../../../components/WorktopColorSelect";
import WorktopColorImages from "../../../components/WorktopColorSelect/WorktopColorImages";
import MobileFrontColorImages from "../../../components/FrontColorSelect/MobileFrontColorImages";
import Button from "../../../components/UI/Button";

import { bodyColors } from "../../../api/colors";

const ColorSelectorMobile = ({
  selectedColor,
  setSelectedColor,
  onSubmit,
  apiData,
}) => {
  const defaultSelectedFront = {};

  const defaultSelectedWorktop = {};

  const [selectedFront, setSelectedFront] = useState(defaultSelectedFront);
  const [selectedBody, setSelectedBody] = useState(null);
  const [selectedWorktop, setSelectedWorktop] = useState(
    defaultSelectedWorktop
  );
  const classes = useStyles();

  /**
   * SELECTED FRONT START
   */
  //fetch Front Color from API
  const frontColor = apiData.filter((colors) => colors.type === "front");

  const onSelectFront = (type, indx) => {
    const updateVal = selectedFront[type] === indx ? null : indx;

    setSelectedFront({
      [type]: updateVal,
    });
  };

  useEffect(() => {
    const selectedFrontKeys = Object.keys(selectedFront);
    if (selectedFrontKeys.length) {
      const selectedFrontData = selectedFrontKeys.filter(
        (key) => selectedFront[key] !== null
      );
      if (selectedFrontData.length) {
        let selectedFrontDataValue = {};
        selectedFrontData.map((key) => {
          selectedFrontDataValue[key] = selectedFront[key];
        });
        setSelectedColor({
          ...selectedColor,
          front: selectedFrontDataValue,
        });
      } else {
        setSelectedColor({
          ...selectedColor,
          front: {},
        });
      }
    } else {
      setSelectedColor({
        ...selectedColor,
        front: {},
      });
    }
  }, [selectedFront]);

  const selectedFrontKey = Object.keys(selectedFront).length
    ? Object.keys(selectedFront)[0]
    : null;
  const selectedFrontValue = Object.values(selectedFront).length
    ? Object.values(selectedFront)[0]
    : null;
  const getSelectedFront = (uuid) => {
    if (selectedFrontKey === uuid) {
      return selectedFrontValue;
    } else {
      return null;
    }
  };

  /**
   * SELECTED FRONT END
   */

  /**
   * SELECTED WORKTOP START
   */
  //Fetch Data from Api
  const workTopColor = apiData.filter(
    (colors) => colors.type === "Arbeitsplatte"
  );

  const onSelectWorktop = (type, indx) => {
    const updateVal = selectedWorktop[type] === indx ? null : indx;

    setSelectedWorktop({
      [type]: updateVal,
    });
  };

  useEffect(() => {
    const selectedWorktopKeys = Object.keys(selectedWorktop);
    if (selectedWorktopKeys.length) {
      const selectedWorktopData = selectedWorktopKeys.filter(
        (key) => selectedWorktop[key] !== null
      );

      if (selectedWorktopData.length) {
        let selectedWorktopDataValue = {};
        selectedWorktopData.map((key) => {
          selectedWorktopDataValue[key] = selectedWorktop[key];
        });

        setSelectedColor({
          ...selectedColor,
          worktop: selectedWorktopDataValue,
        });
      } else {
        setSelectedColor({
          ...selectedColor,
          worktop: {},
        });
      }
    } else {
      setSelectedColor({
        ...selectedColor,
        worktop: {},
      });
    }
  }, [selectedWorktop]);

  const selectedWorktopKey = Object.keys(selectedWorktop).length
    ? Object.keys(selectedWorktop)[0]
    : null;
  const selectedWorktopValue = Object.values(selectedWorktop).length
    ? Object.values(selectedWorktop)[0]
    : null;

  const getSelectedWorktop = (uuid) => {
    if (selectedWorktopKey === uuid) {
      return selectedWorktopValue;
    } else {
      return null;
    }
  };

  /**
   * SELECTED WORKTOP END
   */

  /**
   * SELECTED BODY START
   */

  const onSelectBody = (indx) => {
    const updateVal = selectedBody === indx ? null : indx;
    setSelectedBody(updateVal);
  };

  useEffect(() => {
    setSelectedColor({
      ...selectedColor,
      body: selectedBody,
    });
  }, [selectedBody]);

  /**
   * SELECTED BODY END
   */

  const { config, dispatch } = useContext(ChooseShapeContext);

  const displayStep2 = config[constant.DOTS] === 2 ? "block" : "none";
  const displayStep3 = config[constant.DOTS] === 3 ? "block" : "none";

  const next2ButtonActive = () => {
    const activeFront = Object.keys(selectedColor.front).length > 0;
    const activeBody = selectedColor.body !== null;

    if (activeFront && activeBody) {
      return true;
    } else {
      return false;
    }
  };

  const next3ButtonActive = () => {
    const activeFront = Object.keys(selectedColor.worktop).length > 0;

    if (activeFront) {
      return true;
    } else {
      return false;
    }
  };

  const onClickNext2 = () => {
    dispatch({
      [constant.DOTS]: 3,
    });
  };

  const onClickNext3 = () => {
    dispatch({ [constant.DOTS]: 4 });
    onSubmit();
  };

  return (
    <>
      <div className={classes.mobileRightSideContainer}>
        <div style={{ display: displayStep2 }}>
          {/*
           * BodyColorSelect Start
           */}
          <MobileBodyColorSelect title="Wähle die Farbe deines Korpus">
            <Grid container className={classes.BodyColorSelectContainer}>
              {bodyColors.map((obj, indx) => (
                <Grid xs={5} className={classes.bodyGridMargin} key={`${indx}`}>
                  <div
                    onClick={() => onSelectBody(indx)}
                    className={classes.BodyImageContainer}
                  >
                    <BodyColorImages
                      selected={indx === selectedBody}
                      obj={obj}
                      selectedBody={selectedBody}
                    />
                  </div>
                  <div className={classes.categoryTitle}>{obj.category}</div>
                </Grid>
              ))}
            </Grid>
          </MobileBodyColorSelect>
          {/*
           * BodyColorSelect End
           */}

          {/*
           * FrontColorSelect Start
           */}
          <MobileFrontColorSelect title="Wähle die Farbe deiner Front">
            {/* MattLackiert Start */}
            {frontColor &&
              frontColor.map((obj, indx) => (
                <Grid container className={classes.FrontColorSelectContainer}>
                  <Grid xs={12} key={`${indx}`}>
                    <div className={classes.categoryTitle}>{obj.title}</div>
                    <Grid
                      container
                      xs={12}
                      className={`${classes.FrontImageContainer} ${classes.borderBottom}`}
                    >
                      <MobileFrontColorImages
                        obj={obj}
                        onSelect={(objIndx) => onSelectFront(obj.UUID, objIndx)}
                        selectedFront={getSelectedFront(obj.UUID)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </MobileFrontColorSelect>
          {/*
           * FrontColorSelect End
           */}
          <Button onClick={onClickNext2} active={next2ButtonActive()}>
            WEITER
          </Button>
        </div>
        <div style={{ display: displayStep3 }}>
          {/*
           * WorktopColorSelect Start
           */}
          <WorktopColorSelect title="Wähle Material und Farbe deiner Arbeitsplatte ">
            <Grid container className={classes.WorktopColorSelectContainer}>
              {workTopColor.map((obj, indx) => (
                <Grid xs={12} key={`${indx}`}>
                  <div className={classes.categoryTitle}>{obj.title}</div>
                  <div className={classes.WorktopImageContainer}>
                    <WorktopColorImages
                      data={obj.colors}
                      onSelect={(objIndx) => onSelectWorktop(obj.UUID, objIndx)}
                      selectedWorktop={getSelectedWorktop(obj.UUID)}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </WorktopColorSelect>
          {/*
           * WorktopColorSelect End
           */}
          <Button onClick={onClickNext3} active={next3ButtonActive()}>
            WEITER
          </Button>
        </div>
      </div>
    </>
  );
};
export default ColorSelectorMobile;
