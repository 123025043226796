import { createContext, useState, useEffect } from "react";

import { getMaterialsColorsData } from "../helper/api";

export const DataFetchContext = createContext();

export const DataProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    getMaterialsColorsData().then((res) => {
      setLoading(false);
      if (res.success) {
        setApiData(res.data);
        setError("");
      } else {
        setApiData({});
        setError(res.message);
      }
    });
  }, []);
  return (
    <DataFetchContext.Provider value={{ apiData, loading, error }}>
      {children}
    </DataFetchContext.Provider>
  );
};
