import displayImage1 from "../assets/images/shapePicker/1.png";
import displayImage2 from "../assets/images/shapePicker/2.png";
import displayImage3 from "../assets/images/shapePicker/3.png";
import displayImage4 from "../assets/images/shapePicker/4.png";
import displayImage5 from "../assets/images/shapePicker/5.png";
import displayImage6 from "../assets/images/shapePicker/6.png";
import displayImage7 from "../assets/images/shapePicker/7.png";
import displayImage8 from "../assets/images/shapePicker/8.png";

import standard from "../assets/images/overviewImages/standard.png";
import professional from "../assets/images/overviewImages/profession.png";
import premium from "../assets/images/overviewImages/premium.png";

import {
  Einzeilig,
  Zweizeilig,
  UForm,
  LForm,
  EinzeiligInsel,
  LFormInsel,
  UFormInsel,
  TwoBlöckeInsel,
} from "../assets/icons/shapePicker";

export const lengthSliderMarks = [
  {
    value: 2,
    label: "2m",
  },
  {
    value: 20,
    label: "20m",
  },
];

export const kitchenShape = [
  {
    index: 0,
    type: "Einzeilig",
    image: displayImage1,
    icon: Einzeilig,
  },
  {
    index: 1,
    type: "zweizeilig",
    image: displayImage2,
    icon: Zweizeilig,
  },
  {
    index: 2,
    type: "L Form",
    image: displayImage3,
    icon: LForm,
  },
  {
    index: 3,
    type: "U Form",
    image: displayImage4,
    icon: UForm,
  },
  {
    index: 4,
    type: "Einzeilig + Insel",
    image: displayImage5,
    icon: EinzeiligInsel,
  },
  {
    index: 5,
    type: "L Form + Insel",
    image: displayImage6,
    icon: LFormInsel,
  },
  {
    index: 6,
    type: "U Form + Insel",
    image: displayImage7,
    icon: UFormInsel,
  },
  {
    index: 7,
    type: "2 Blöcke + Insel",
    image: displayImage8,
    icon: TwoBlöckeInsel,
  },
];

export const HeaderAndDescription = [
  {
    title: "Wähle die Form deiner Küche",
    description:
      "Sollte die Form deiner zukünftigen Küche nicht abgebildet sein, wähle bitte die Form, die deiner Küche am ähnlichsten ist. Wichtig ist , dass du die Gesamtlänge passend angibst!",
  },
  {
    title: "Gesamtlänge deiner Küche",
    description:
      "Wie viele Laufmeter hat deine Küche insgesamt (+ Kochinsel, falls gewünscht)",
  },
];

export const productSliderData = [
  {
    label: "Standard",
    image: standard,
    info:
      "Tolle Markengeräte im unteren Preissegment mit Standardfunktionen.",
    price: 1500,
  },
  {
    label: "Professional",
    image: professional,
    info: "Einbaugeräte für den Profi - nur die hochwertigsten Marken wie Miele & Co.\nDie genaue Auswahl triffst du mit unserem Tischler vor Ort!",
    price: 5000,
  },
  {
    label: "Premium",
    image: premium,
    info:
      "Geräte für den Hobbykoch - Bosch , Siemens , Küppersbusch , etc.. - die genaue Auswahl triffst du bei der Detailplanung mit unserem Tischler vor Ort!",
    price: 3000,
  },
];
