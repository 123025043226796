import { useState } from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./style";
import InputText from "../../components/UI/Input";
import Button from "../../components/UI/Button";
import ImagePicker from "../../components/UI/MobileImagePicker";
import Checkbox from "../../components/UI/Checkbox";
// import { toBase64 } from "../../helper/functions";

const defaultError = { telephone: "", mail: "", formMail: "", file: "" };

const Right = ({ onSubmitSampleRequest, onSubmit3dOrder }) => {
  const classes = useStyles();

  const [emailFormData, setEmailFormData] = useState({
    email: "",
    file: null,
  });

  const [contactFormData, setContactFormData] = useState({
    fullName: "",
    address: "",
    tel: "",
    mail: "",
    plz: "",
    useAGB: false,
    useNewsLetter: false,
  });
  const [error, setError] = useState(defaultError);

  //Validate Email
  const validateEmail = (type, value) => {
    if (!value) {
      const msg = "Please Fill this Field";
      setError({ ...error, [type]: msg });
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      const msg = "Please Valid Enter Email";
      setError({ ...error, [type]: msg });
    } else {
      setError({ ...error, [type]: "" });
    }
  }; //validate Telephone
  const validateTelephone = (type, value) => {
    if (!/^\d+$/.test(value)) {
      const msg = "Please Enter Valid telephone";
      setError({ ...error, [type]: msg });
    } else {
      setError({ ...error, [type]: "" });
    }
  };
  const onChangeEmailForm = (key, value) => {
    validateEmail("mail", value);
    setEmailFormData({
      ...emailFormData,
      [key]: value,
    });
  };

  const onChangeContactForm = (e, type) => {
    const { name, value } = e.target;
    if (type === "formMail") {
      validateEmail(type, value);
    }
    if (type === "telephone") {
      validateTelephone(type, value);
    }
    setContactFormData({
      ...contactFormData,
      [name]: value,
    });
  };

  const checkEmailButtonActive = () => {
    return !!emailFormData.email.length && !error.mail && emailFormData.file;
  };

  const checkContactButtonActive = () => {
    return !!(
      contactFormData.fullName &&
      contactFormData.address &&
      contactFormData.tel &&
      contactFormData.mail &&
      contactFormData.plz &&
      contactFormData.useAGB &&
      !error.formMail &&
      !error.telephone
    );
  };

  const onChangeFile = async (e) => {
    const fileData = e.target.files[0];
    if (
      (fileData !== null &&
        (fileData.type == "image/png" || fileData.type == "application/pdf")) ||
      fileData.type == "image/jpg" ||
      fileData.type == "application/pdf" ||
      fileData.type == "image/jpeg"
    ) {
      if (fileData.size > 2000000) {
        setEmailFormData({
          ...emailFormData,
          file: null,
        });
        setError({
          ...error,
          ["file"]: "Datei Darf nicht gröser als 2MB sein.",
        });
      } else {
        setEmailFormData({
          ...emailFormData,
          file: fileData,
        });
        setError({ ...error, ["file"]: "" });

        setError({ ...error, ["file"]: "" });
      }
    } else {
      const msg =
        "Ungültiges Format. Bitte wählen Sie eine PNG-, JPG- oder PDF-Datei.";
      setEmailFormData({
        ...emailFormData,
        file: null,
      });
      setError({ ...error, ["file"]: msg });
    }
  };

  const onClickSubmitSample = () => {
    if (checkContactButtonActive()) {
      onSubmitSampleRequest(contactFormData);
    }
  };

  const onClickSubmit3dOrder = () => {
    if (checkEmailButtonActive()) {
      onSubmit3dOrder(emailFormData);
    }
  };

  return (
    <Grid xs={12} md={8}>
      <div className={classes.mobileRightContainer}>
        <div className={classes.mobileHeader}>Wir planen für dich</div>
        <div className={classes.firstInfoText}>
          Nun wissen unsere Küchendesigner welche Stil und Materialien dir am
          Besten gefallen. Wenn du jetzt auch noch einen Grundrissplan oder ein
          Foto von dem Raum in dem deine zukünftige Küche stehen soll hochlädst,
          fertigen unsere Experten gerne einen ersten individuellen 3D Entwurf
          für dich an - natürlich völlig kostenlos & unverbindlich.
        </div>
        <div className={classes.convinceTitle}>Überzeuge dich selbst</div>
        {/*
         * Image selector
         */}
        <div className={classes.imagePickerContainer}>
          <ImagePicker
            selected={!!emailFormData.file}
            onChange={onChangeFile}
          />
          <p className={classes.error}>{error.file}</p>
        </div>
        {/*
         * Image selector End
         */}
        <div className={classes.receiveMailText}>
          Du erhältst deinen 3D Entwurf in den nächsten 1-2 Tagen per Mail.
        </div>
        {
          // Email input & Submit Button
        }

        <div className={classes.formInput}>
          <InputText
            label="Mail"
            name="email"
            value={emailFormData.email}
            onChange={(e) => onChangeEmailForm("email", e.target.value)}
          />
          <p className={classes.error}>{error.mail}</p>
        </div>

        <div className={classes.emailButton}>
          <Button
            active={checkEmailButtonActive()}
            round
            onClick={onClickSubmit3dOrder}
          >
            3D Entwurf kostenlos anfordern
          </Button>
        </div>

        <div className={classes.angreifenContainer}>
          <div className={classes.convinceTitle}>
            Du willst unsere Oberflächen angreifen ?
          </div>
          <div className={classes.angreifenInfo}>
            Kein Problem. Gerne senden wir dir Musterstücke deiner ausgewählten
            Oberflächen & Farben per Post nach Hause - auch das natürlich völlig
            kostenlos und unverbindlich. Wir müssen nur noch wissen wohin das
            Paket gehen soll und schon sind deine Muster unterwegs zu dir.
          </div>
        </div>
        {/*
         *Form Start
         */}
        <div container className={classes.formContainer}>
          <div className={classes.formInput}>
            <InputText
              label="Vor - & Nachname"
              name="fullName"
              value={contactFormData.fullName}
              onChange={onChangeContactForm}
            />
          </div>
          <div className={classes.formInput}>
            <InputText
              label="Adresse"
              name="address"
              value={contactFormData.address}
              onChange={onChangeContactForm}
            />
          </div>
          <div className={classes.formInput}>
            <InputText
              label="Tel."
              name="tel"
              value={contactFormData.tel}
              onChange={(e) => onChangeContactForm(e, "telephone")}
            />
            <p className={classes.error}>{error.telephone}</p>
          </div>
          <div className={classes.formInput}>
            <InputText
              label="Mail"
              name="mail"
              value={contactFormData.mail}
              onChange={(e) => onChangeContactForm(e, "formMail")}
            />
            <p className={classes.error}>{error.formMail}</p>
          </div>
          <div className={classes.formInput}>
            <InputText
              label="PLZ"
              name="plz"
              value={contactFormData.plz}
              onChange={onChangeContactForm}
            />
          </div>
          <div className={classes.formInput}>
            <div className={classes.newsletterCheckbox}>
              <Checkbox
                isAgb
                label="AGB"
                name="agb"
                onChange={() =>
                  onChangeContactForm(
                    {
                      target: {
                        name: "useAGB",
                        value: !contactFormData.useAGB,
                      },
                    },
                    "useAGB"
                  )
                }
                active={contactFormData.useAGB}
              />

              <Checkbox
                label="Newsletter"
                name="useNewsLetter"
                onChange={() =>
                  onChangeContactForm(
                    {
                      target: {
                        name: "useNewsLetter",
                        value: !contactFormData.useNewsLetter,
                      },
                    },
                    "useNewsLetter"
                  )
                }
                active={contactFormData.useNewsLetter}
              />
            </div>
          </div>
          <div className={classes.anfordernBtn}>
            <Button
              active={checkContactButtonActive()}
              round
              onClick={onClickSubmitSample}
            >
              Muster kostenlos anfordern
            </Button>
          </div>
        </div>
        {/*
         *Form End
         */}
      </div>
    </Grid>
  );
};

export default Right;
