import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: "53px",
    height: "51px",
    background: "#F9F9F9",
    boxShadow: "0px 4px 8px rgba(174, 174, 174, 0.35)",
    borderRadius: "8px",
  },
  selectedButton: {
    background: "#D3CDC3",
  },
  itemIcon: {
    margin: "0 3%",
  },
}));

const ChooseShape = ({ children, selected = false, onSelect }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.itemIcon}>
        <Button
          variant="contained"
          color="default"
          onClick={onSelect}
          className={`${classes.button} ${
            selected ? classes.selectedButton : ""
          }`}
        >
          {children}
        </Button>
      </div>
    </>
  );
};

export default ChooseShape;
