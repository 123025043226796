import { useState, useCallback } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CustomSlider from "./customSlider";
import _ from "lodash";
import { lengthSliderMarks as marks } from "../../../api/index";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: 0,
    },
    margin: {
      height: theme.spacing(3),
    },
    rangeInput: {
      flex: 1,
      color: "#000",
      display: "flex",
      justifyContent: "flex-end",
    },
    input: {
      color: "#000",
      borderRadius: 0,
      height: 28,
      width: 50,
      padding: 0,
      fontSize: "12px",
    },
    outlineInput: {
      padding: "18.5px 5px",
      color: "#383838",
    },
  })
);

const InputSlider = ({ kitchenLengthValue, setKitchenLengthValue }) => {
  const [sliderValue, setSliderValue] = useState(kitchenLengthValue);
  const [sliderInputValue, setSliderInputValue] = useState(
    `${kitchenLengthValue}m`
  );
  const classes = useStyles();

  const minValue = marks[0].value;
  const maxValue = marks[1].value;

  const validation = useCallback(
    _.debounce((value) => {
      const parsedValue = parseInt(value);
      const includeM = value.split("m");
      if (parsedValue) {
        if (parsedValue < minValue) {
          setSliderInputValue(minValue + "m");
          setKitchenLengthValue(minValue);
        } else if (parsedValue > maxValue) {
          setSliderInputValue(maxValue + "m");
          setKitchenLengthValue(maxValue);
        } else if (includeM.length !== 2) {
          setSliderInputValue(parsedValue + "m");
          setKitchenLengthValue(parsedValue);
        }
      }
    }, 500),
    []
  );

  const onChangeSliderText = (e) => {
    const { value } = e.target;
    const parsedValue = parseInt(value);

    const reg = /^\d+$/;
    const includeM = value.split("m");
    if (reg.test(value) || includeM.length === 2 || value.length === 0) {
      const parsedFinalVal = parsedValue ? parsedValue + "" : "";
      validation(parsedFinalVal);
      setSliderInputValue(parsedFinalVal);
    }

    if (parsedValue && parsedValue >= minValue && parsedValue <= maxValue) {
      setSliderValue(parsedValue);
      setKitchenLengthValue(parsedValue);
    } else if (parsedValue && parsedValue > maxValue) {
      setSliderValue(maxValue);
      setKitchenLengthValue(maxValue);
    } else if (parsedValue && parsedValue < minValue) {
      setSliderValue(minValue);
      setKitchenLengthValue(minValue);
    }
  };

  const onChangeSlider = (e, val) => {
    setSliderValue(val);
    const updateTextValue = {
      target: {
        value: val + "",
      },
    };
    onChangeSliderText(updateTextValue);
  };

  return (
    <>
      <div className={classes.rangeInput}>
        <TextField
          variant="outlined"
          InputProps={{
            className: classes.input,
            classes: {
              input: classes.outlineInput,
            },
          }}
          value={sliderInputValue}
          onChange={onChangeSliderText}
        />
      </div>
      <div className={classes.root}>
        <CustomSlider
          aria-label="ios slider"
          defaultValue={10}
          valueLabelDisplay="auto"
          value={sliderValue}
          min={2}
          max={20}
          onChange={onChangeSlider}
          marks={marks}
        />
      </div>
    </>
  );
};

export default InputSlider;
