import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import FrontColorSelect from "../../../components/FrontColorSelect";
import BodyColorSelect from "../../../components/BodyColorSelect";
import BodyColorImages from "../../../components/BodyColorSelect/BodyColorImages";
import WorktopColorSelect from "../../../components/WorktopColorSelect";
import WorktopColorImages from "../../../components/WorktopColorSelect/WorktopColorImages";
import FrontColorImages from "../../../components/FrontColorSelect/FrontColorImages";

import { bodyColors } from "../../../api/colors";
import { useStyles } from "./style";

const ColorSelector = ({ selectedColor, setSelectedColor, apiData }) => {
  const defaultSelectedFront = {};

  const defaultSelectedWorktop = {};

  const [selectedFront, setSelectedFront] = useState(defaultSelectedFront);
  const [selectedBody, setSelectedBody] = useState(null);
  const [selectedWorktop, setSelectedWorktop] = useState(
    defaultSelectedWorktop
  );
  const classes = useStyles();

  /**
   * SELECTED FRONT START
   */
  //fetch Front Color from API
  let frontColor = apiData.filter((colors) => colors.type === "front");

  const onSelectFront = (type, indx) => {
    const updateVal = selectedFront[type] === indx ? null : indx;

    setSelectedFront({
      [type]: updateVal,
    });
  };

  useEffect(() => {
    const selectedFrontKeys = Object.keys(selectedFront);
    if (selectedFrontKeys.length) {
      const selectedFrontData = selectedFrontKeys.filter(
        (key) => selectedFront[key] !== null
      );
      if (selectedFrontData.length) {
        let selectedFrontDataValue = {};
        selectedFrontData.map((key) => {
          selectedFrontDataValue[key] = selectedFront[key];
        });
        setSelectedColor({
          ...selectedColor,
          front: selectedFrontDataValue,
        });
      } else {
        setSelectedColor({
          ...selectedColor,
          front: {},
        });
      }
    } else {
      setSelectedColor({
        ...selectedColor,
        front: {},
      });
    }
  }, [selectedFront]);

  /**
   * SELECTED FRONT END
   */

  /**
   * SELECTED WORKTOP START
   */
  //Fetch Data from Api
  const workTopColor = apiData.filter(
    (colors) => colors.type === "Arbeitsplatte"
  );

  const onSelectWorktop = (type, indx) => {
    const updateVal = selectedWorktop[type] === indx ? null : indx;

    setSelectedWorktop({
      [type]: updateVal,
    });
  };

  useEffect(() => {
    const selectedWorktopKeys = Object.keys(selectedWorktop);
    if (selectedWorktopKeys.length) {
      const selectedWorktopData = selectedWorktopKeys.filter(
        (key) => selectedWorktop[key] !== null
      );

      if (selectedWorktopData.length) {
        let selectedWorktopDataValue = {};
        selectedWorktopData.map((key) => {
          selectedWorktopDataValue[key] = selectedWorktop[key];
        });

        setSelectedColor({
          ...selectedColor,
          worktop: selectedWorktopDataValue,
        });
      } else {
        setSelectedColor({
          ...selectedColor,
          worktop: {},
        });
      }
    } else {
      setSelectedColor({
        ...selectedColor,
        worktop: {},
      });
    }
  }, [selectedWorktop]);

  /**
   * SELECTED WORKTOP END
   */

  /**
   * SELECTED BODY START
   */

  const onSelectBody = (indx) => {
    const updateVal = selectedBody === indx ? null : indx;
    setSelectedBody(updateVal);
  };

  useEffect(() => {
    setSelectedColor({
      ...selectedColor,
      body: selectedBody,
    });
  }, [selectedBody]);

  /**
   * SELECTED BODY END
   */

  // console.log("selectedColor :: ", selectedColor);

  const selectedFrontKey = Object.keys(selectedFront).length
    ? Object.keys(selectedFront)[0]
    : null;
  const selectedFrontValue = Object.values(selectedFront).length
    ? Object.values(selectedFront)[0]
    : null;

  const selectedWorktopKey = Object.keys(selectedWorktop).length
    ? Object.keys(selectedWorktop)[0]
    : null;
  const selectedWorktopValue = Object.values(selectedWorktop).length
    ? Object.values(selectedWorktop)[0]
    : null;

  const getSelectedFront = (uuid) => {
    if (selectedFrontKey === uuid) {
      return selectedFrontValue;
    } else {
      return null;
    }
  };
  const getSelectedWorktop = (uuid) => {
    if (selectedWorktopKey === uuid) {
      return selectedWorktopValue;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className={classes.rightSideContainer}>
        {/*
         * BodyColorSelect Start
         */}
        <BodyColorSelect title="Wähle die Farbe deines Korpus">
          <Grid container className={classes.BodyColorSelectContainer}>
            {bodyColors.map((obj, indx) => (
              <Grid xs={1} className={classes.bodyGridMargin} key={indx}>
                <div
                  onClick={() => onSelectBody(indx)}
                  className={classes.BodyImageContainer}
                >
                  <BodyColorImages
                    selected={indx === selectedBody}
                    obj={obj}
                    selectedBody={selectedBody}
                  />
                </div>
                <div className={classes.categoryTitle}>{obj.category}</div>
              </Grid>
            ))}
          </Grid>
        </BodyColorSelect>
        {/*
         * BodyColorSelect End
         */}
        {/*
         * FrontColorSelect Start
         */}
        <FrontColorSelect title="Wähle die Farbe deiner Front">
          {frontColor.map((obj, indx) => (
            <Grid
              container
              className={classes.FrontColorSelectContainer}
              key={`${indx}`}
            >
              <Grid xs={12}>
                <div className={classes.categoryTitle}>{obj.title}</div>
                <div className={classes.FrontImageContainer}>
                  <FrontColorImages
                    data={obj.colors}
                    onSelect={(objIndx) => onSelectFront(obj.UUID, objIndx)}
                    selectedFront={getSelectedFront(obj.UUID)}
                  />
                </div>
              </Grid>
            </Grid>
          ))}
        </FrontColorSelect>
        {/* FrontColorSelect End */}

        {/*
         * WorktopColorSelect Start
         */}
        <WorktopColorSelect title="Wähle Material und Farbe deiner Arbeitsplatte ">
          <Grid container className={classes.WorktopColorSelectContainer}>
            {workTopColor.map((obj, indx) => (
              <Grid xs={6} key={`${indx}`}>
                <div className={classes.categoryTitle}>{obj.title}</div>
                <div className={classes.WorktopImageContainer}>
                  <WorktopColorImages
                    data={obj.colors}
                    onSelect={(objIndx) => onSelectWorktop(obj.UUID, objIndx)}
                    selectedWorktop={getSelectedWorktop(obj.UUID)}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </WorktopColorSelect>
        {/*
         * WorktopColorSelect End
         */}
      </div>
    </>
  );
};
export default ColorSelector;
