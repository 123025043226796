import { useStyles } from "./style";

const BodyColorSelect = ({ title, children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.childrenContainer}>{children}</div>
      </div>
    </>
  );
};

export default BodyColorSelect;
