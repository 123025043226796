import { makeStyles } from "@material-ui/core/styles";
import { TABLET } from "../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: "relative",
    [theme.breakpoints.down(TABLET)]: {
      margin: "10px 0",
    },
  },
  title: {
    fontSize: "23px",
    marginTop: 15,
    fontWeight: 700,
    color: "#383838",
    [theme.breakpoints.down(TABLET)]: {
      fontSize: "16px",
      fontWeight: "700",
      textAlign: "center",
    },
  },
  childrenContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },

  selectedImageBox: {
    height: "38px",
    width: "38px",
    marginRight: "1%",
    transform: "scale(1.4)",
    [theme.breakpoints.down(TABLET)]: {
      display: "flex",
      justifyContent: "center",
      height: "28px",
      width: "28px",
      margin: "5px 0",
    },
  },
  imageBox: {
    height: "38px",
    width: "38px",
    marginRight: "1%",
    [theme.breakpoints.down(TABLET)]: {
      display: "flex",
      justifyContent: "center",
      // height: "25px",
      // width: "25px",
      height: "25%",
      width: "25%",
      margin: "15px 0",
    },
  },
  colorsImage: {
    height: "100%",
    maxWidth: "100%",
    borderRadius: "50%",
    [theme.breakpoints.down(TABLET)]: {
      height: 40,
      width: 40,
    },
  },
  selectedColorsImage: {
    height: "100%",
    maxWidth: "100%",
    borderRadius: "50%",
    [theme.breakpoints.down(TABLET)]: {
      transform: "scale(1.3)",
      height: 43,
      width: 43,
    },
  },
}));
