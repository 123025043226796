import { useContext } from "react";
import { ChooseShapeContext, constant } from "../../context/ChooseShape";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import { BackIcon, LOGO, MenuIcon } from "../../assets/icons";
import { useStyles } from "./style";

const Header = ({ showBack, onClickBack, onClickMenu }) => {
  const classes = useStyles();

  const rootClasses = {
    // colorPrimary: {
    //   backgroundColor: "#FFFFFF",
    // },
  };

  const { config } = useContext(ChooseShapeContext);

  const onClickWebBack = () => {
    if (config[constant.DOTS] === 4) {
      onClickBack(3);
    } else {
      onClickBack();
    }
  };

  return (
    <div className={classes.root}>
      <AppBar classes={rootClasses} position="static">
        <Toolbar className={classes.headerContainer}>
          <div className={classes.webBackButtonContainer}>
            <IconButton
              onClick={() => onClickWebBack()}
              edge="start"
              disabled={!showBack}
              className={classes.menuButton}
              color="inherit"
              aria-label="back"
            >
              {showBack ? <BackIcon /> : ""}
            </IconButton>
          </div>
          <div className={classes.mobileBackButtonContainer}>
            <IconButton
              onClick={() => onClickBack(1)}
              edge="start"
              disabled={!showBack}
              className={classes.menuButton}
              color="inherit"
              aria-label="back"
            >
              {showBack ? <BackIcon /> : ""}
            </IconButton>
          </div>
          <div>
            <Typography variant="h6" className={classes.title}>
              <LOGO />
            </Typography>
          </div>
          <div>
     
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
