import { makeStyles } from "@material-ui/core/styles";
import { TABLET } from "../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down(TABLET)]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "10px 0",
    },
  },
  title: {
    fontSize: "23px",
    marginTop: 15,
    fontWeight: 700,
    color: "#383838",
    [theme.breakpoints.down(TABLET)]: {
      fontSize: "23px",
      fontWeight: "700",
      display: "flex",
      flexDirection: "row",
      flex: 1,
    },
  },
  childrenContainer: {
    display: "flex",
    flex: 1,
    // marginRight: "1%",
    marginLeft: "2%",
    [theme.breakpoints.down(TABLET)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      flex: 1,
    },
  },
}));
