import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TABLET } from "../../config/styleConfig";

import Tooltip from "@material-ui/core/Tooltip";

const ColorTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 15,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  selectedImageBox: {
    height: "60px",
    [theme.breakpoints.down(TABLET)]: {
      height: 72,
      transform: "scale(1.3)",
    },
    marginRight: "1%",
    transform: "scale(1.3)",
  },
  imageBox: {
    height: "60px",
    marginRight: "1%",
    [theme.breakpoints.down(TABLET)]: {
      height: 70,
    },
  },
  colorsImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

const BodyColorImages = ({ obj, selected, selectedBody }) => {
  const classes = useStyles();

  return (
    <>
      {obj.image.map((val, indx) => (
        <div
          className={selected ? classes.selectedImageBox : classes.imageBox}
          key={`${indx}`}
        >
          {/* <img src={val} className={classes.colorsImage} key={indx} /> */}
          {val.colorName ? (
            <ColorTooltip title={val.colorName} placement="top" arrow>
              <img
                src={val.image}
                alt={val.colorName}
                className={classes.colorsImage}
                key={`${indx}`}
              />
            </ColorTooltip>
          ) : (
            <img
              src={val.image}
              alt={indx}
              className={classes.colorsImage}
              key={`${indx}`}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default BodyColorImages;
