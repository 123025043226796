import { makeStyles } from "@material-ui/core/styles";
import { MOBILE_SCREEN, MID_SCREEN, TABLET } from "../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 20,
    [theme.breakpoints.down(TABLET)]: {
      padding: 5,
    },
  },
  leftContainer: {
    padding: "10px 30px",
    [theme.breakpoints.down(TABLET)]: {
      marginBottom: 50,
      padding: 10,
    },
  },
  rootRight: {
    display: "flex",
    flex: 1,
    padding: "10px 30px",
    [theme.breakpoints.down(TABLET)]: {
      display: "none",
    },
  },
  imgContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    maxHeight: 340,
  },
  imgResponsive: {
    maxWidth: "100%",
  },
  chooseDeviceTitle: {
    fontWeight: 700,
    color: "#383838",
    fontSize: 25,
    textAlign: "center",
    marginBottom: 20,
  },
  checkboxContainer: {
    marginTop: 30,
  },
  mobileSubmitButtonContainer: {
    display: "none",
    [theme.breakpoints.down(TABLET)]: {
      display: "block",
    },
  },
  rightContainer: {
    display: "block",
    padding: 20,
    [theme.breakpoints.down(TABLET)]: {
      display: "none",
      padding: 0,
    },
  },
  mobileRightContainer: {
    display: "none",
    padding: 5,
    [theme.breakpoints.down(TABLET)]: {
      marginTop: -50,
      display: "block",
      padding: 20,
    },
  },
  tableContainer: {},
  tableRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px 0",
    alignItems: "center",
  },
  priceDetailsContainer: {
    display: "flex",
    flex: 1,
  },
  priceContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  priceInfoContainer: {
    display: "flex",
    flex: 1,
    borderLeft: "1px solid #333333",
    padding: 20,
    lineHeight: 2,
  },
  rightTitle: {
    margin: "30px 0 10px",
    fontSize: 25,
    color: "#383838",
    fontWeight: 700,
  },
  tableTitle: {
    fontSize: 20,
    color: "#383838",
    fontWeight: 700,
  },
  tableValue: {
    color: "#383838",
  },
  rowBorder: {
    borderBottom: "1px solid #333333",
  },
  materialCostTitle: {
    marginTop: 30,
    fontSize: 25,
    color: "#383838",
    fontWeight: 700,
    maxWidth: "75%",
    [theme.breakpoints.down(TABLET)]: {
      marginTop: 30,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 600,
      maxWidth: "unset",
      textAlign: "center",
    },
  },
  priceText: {
    color: "#2F80ED",
    fontSize: 25,
    fontWeight: 600,
    marginRight: 15,
    [theme.breakpoints.down(TABLET)]: {
      fontSize: 25,
      fontWeight: 600,
      padding: "12px 0",
      marginRight: 0,
      marginLeft: "18%",
    },
  },
  choiceYoursTitle: {
    margin: "30px 0",
    textAlign: "center",
    fontSize: 25,
    color: "#383838",
    fontWeight: 700,
    [theme.breakpoints.down(TABLET)]: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  btnBox: {
    margin: "0 20px",
    padding: "0 20px",
    [theme.breakpoints.down(TABLET)]: {
      margin: "0 5px",
      padding: "0 5px",
      width: "100%",
    },
  },
  btnNamePadding: {
    padding: "0 15px",
  },
  mobileRightTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#383838",
    textAlign: "center",
    marginBottom: 20,
    marginTop: 20,
  },
  mobileTableContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mobileTableRow: {
    display: "flex",
  },
  mobileTableColumn: {
    display: "flex",
    flex: 1,
    color: "#383838",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #4F4F4F",
    marginLeft: 10,
    padding: 10,
  },
  mobileColumnCount: {
    display: "flex",
    padding: "10px 0",
  },
  counterValue: {
    backgroundColor: "#000",
    color: "#FFF",
    width: 30,
    height: 30,
    borderRadius: 15,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mobilePriceContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 20,
    width: "100%",
  },
  mobileBtnText: {
    fontSize: 12,
  },

  infoContainer: {
    position: "fixed",
    backgroundColor: "#FFF",
    left: 0,
    right: 0,
    bottom: 0,
    top: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 35,
  },
  activeLabelInfo: {
    marginTop: 30,
    textAlign: "center",
    lineHeight: 1.5,
    color: "#383838",
  },
  closeArrowButton: {
    marginTop: 20,
  },
  closeArrowContainer: {
    padding: 10,
  },
  infoTextContainer: {
    position: "absolute",
    left: 30,
    zIndex: 1000,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      left: "0 !important",
      right: 0,
      bottom: 0,
      top: "50% !important",
      position: "fixed",
    },
    [theme.breakpoints.only(TABLET)]: {},
    [theme.breakpoints.only(MID_SCREEN)]: {
      left: "20px !important",
    },
  },
  closeArrow: {
    display: "none",
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
  },
  // infoContainer: {
  //   position: "relative",
  //   [theme.breakpoints.only(MOBILE_SCREEN)]: {
  //     position: "fixed",
  //     backgroundColor: "#FFF",
  //     left: 0,
  //     right: 0,
  //     bottom: 0,
  //     top: "45%",
  //     display: "flex",
  //     flexDirection: "column",
  //     alignItems: "center",
  //     padding: 35,
  //   },
  // },
}));
