import { makeStyles } from "@material-ui/core/styles";
import { TABLET } from "../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {},
  title: {
    fontSize: "23px",
    marginTop: 15,
    fontWeight: 700,
    color: "#383838",
    [theme.breakpoints.down(TABLET)]: {
      fontSize: "16px",
      fontWeight: "700",
      textAlign: "center",
      padding: "0 20px",
    },
  },
  childrenContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
}));
