import { useContext } from "react";
import { Grid } from "@material-ui/core";
import HeaderDot from "../../components/HeaderDot";
import { useStyles } from "./style";
import { ChooseShapeContext, constant } from "../../context/ChooseShape";
import ProductSlider from "../../components/UI/ProductSlider";
import MobileProductSlider from "../../components/UI/MobileProductSlider";
import Checkbox from "../../components/UI/Checkbox";
import KitchenImage from "../../components/KitchenImage";

const Left = ({
  selectedOverviewSlide,
  onChangeOverviewSlide,
  selectedKitchenShape,
  useOwnDevice,
  setUseOwnDevice,
}) => {
  const classes = useStyles();

  const { config } = useContext(ChooseShapeContext);

  const display1 = config[constant.DOTS] === 6 ? "block" : "none";

  return (
    <Grid xs={12} md={4} className={classes.leftContainer}>
      <div>
        <HeaderDot value={config[constant.DOTS]} />
        <KitchenImage
          src={selectedKitchenShape.image}
          text={`${selectedKitchenShape.type}`}
        />
        <div style={{ display: display1 }}>
          <div className={classes.chooseDeviceTitle}>Wähle ein Gerätepaket</div>
          <ProductSlider
            onChangeOverviewSlide={onChangeOverviewSlide}
            selectedOverviewSlide={selectedOverviewSlide}
          />
          <MobileProductSlider
            onChangeOverviewSlide={onChangeOverviewSlide}
            selectedOverviewSlide={selectedOverviewSlide}
          />
          <div className={classes.checkboxContainer}>
            <Checkbox
              label="Ich verwende meine eigenen Geräte: "
              onChange={() => setUseOwnDevice(!useOwnDevice)}
              active={useOwnDevice}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
};
export default Left;
