import { Grid } from "@material-ui/core";
import { useStyles } from "./style";

const ColorImages = ({ obj, onSelect, selectedFront }) => {
  const classes = useStyles();

  const checkSelected = (indx) => {
    return indx === selectedFront;
  };

  return (
    <>
      {obj &&
        obj.colors.map((val, indx) => (
          <Grid
            xs={3}
            className={classes.imageBox}
            onClick={() => onSelect(val.UUID)}
            key={`${indx}`}
          >
            <img
              src={val.image}
              alt={val.colorName}
              className={
                checkSelected(val.UUID)
                  ? classes.selectedColorsImage
                  : classes.colorsImage
              }
              key={val.UUID}
            />
          </Grid>
        ))}
    </>
  );
};

export default ColorImages;
