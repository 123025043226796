export const Einzeilig = () => {
  return (
    <svg
      width="9"
      height="22"
      viewBox="0 0 8 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "absolute" }}
    >
      <line
        x1="4"
        y1="1.74846e-07"
        x2="4"
        y2="24"
        stroke="#383838"
        strokeWidth="8"
      />
    </svg>
  );
};
export const Zweizeilig = () => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="4"
        y1="1.74846e-07"
        x2="4"
        y2="24"
        stroke="#383838"
        strokeWidth="8"
      />
      <line
        x1="24"
        y1="1.74846e-07"
        x2="24"
        y2="24"
        stroke="#383838"
        strokeWidth="8"
      />
    </svg>
  );
};

export const UForm = () => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="4" x2="28" y2="4" stroke="#383838" strokeWidth="8" />
      <line
        x1="4"
        y1="1.74846e-07"
        x2="4"
        y2="24"
        stroke="#383838"
        strokeWidth="8"
      />
      <line
        x1="24"
        y1="1.74846e-07"
        x2="24"
        y2="24"
        stroke="#383838"
        strokeWidth="8"
      />
    </svg>
  );
};
export const LForm = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="4"
        y1="1.74846e-07"
        x2="4"
        y2="24"
        stroke="#383838"
        strokeWidth="8"
      />
      <line x1="4" y1="4" x2="22" y2="4" stroke="#383838" strokeWidth="8" />
    </svg>
  );
};
export const EinzeiligInsel = () => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="3.49691e-07"
        y1="4"
        x2="28"
        y2="4"
        stroke="#383838"
        strokeWidth="8"
      />
      <line x1="4" y1="20" x2="24" y2="20" stroke="#383838" strokeWidth="8" />
    </svg>
  );
};
export const LFormInsel = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="-4"
        x2="26"
        y2="-4"
        transform="matrix(-4.37114e-08 1 1 4.37114e-08 8 0)"
        stroke="#383838"
        strokeWidth="8"
      />
      <line
        y1="-4"
        x2="22"
        y2="-4"
        transform="matrix(1 0 0 -1 0 0)"
        stroke="#383838"
        strokeWidth="8"
      />
      <line
        y1="-4"
        x2="13"
        y2="-4"
        transform="matrix(-4.0349e-08 1 1 4.0349e-08 22 13)"
        stroke="#383838"
        strokeWidth="8"
      />
    </svg>
  );
};
export const UFormInsel = () => {
  return (
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="-4"
        x2="18"
        y2="-4"
        transform="matrix(-4.37114e-08 1 1 4.37114e-08 34 0)"
        stroke="#383838"
        strokeWidth="8"
      />
      <line
        y1="-4"
        x2="18"
        y2="-4"
        transform="matrix(-4.37114e-08 1 1 4.37114e-08 8 0)"
        stroke="#383838"
        strokeWidth="8"
      />
      <line
        y1="-4"
        x2="33"
        y2="-4"
        transform="matrix(-1 -1.74846e-07 -1.74846e-07 1 33 8)"
        stroke="#383838"
        strokeWidth="8"
      />
      <line
        y1="-4"
        x2="12"
        y2="-4"
        transform="matrix(1 0 0 -1 11 18)"
        stroke="#383838"
        strokeWidth="8"
      />
    </svg>
  );
};
export const TwoBlöckeInsel = () => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="-4"
        x2="24"
        y2="-4"
        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 0 24)"
        stroke="#383838"
        strokeWidth="8"
      />
      <line
        y1="-4"
        x2="12"
        y2="-4"
        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 20 24)"
        stroke="#383838"
        strokeWidth="8"
      />
      <line
        y1="-4"
        x2="15"
        y2="-4"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 13 0)"
        stroke="#383838"
        strokeWidth="8"
      />
    </svg>
  );
};
