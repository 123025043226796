import { makeStyles } from "@material-ui/core/styles";
import { MOBILE_SCREEN, MID_SCREEN, TABLET } from "../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 20,
    [theme.breakpoints.down(TABLET)]: {
      padding: 5,
    },
  },
  posRelative: {
    position: "relative",
  },
  imgContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    maxHeight: 340,
  },
  imgResponsive: {
    maxWidth: "100%",
  },
  leftContainer: {
    padding: "10px 30px",
    [theme.breakpoints.down(TABLET)]: {
      padding: 10,
    },
  },
  shapeContainer: {
    position: "relative",
    width: 400,
    margin: "auto",
    [theme.breakpoints.only(MID_SCREEN)]: {
      width: 300,
      margin: "auto",
    },
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      width: "unset",
      maxWidth: "100%",
      margin: "auto",
    },
  },
  sliderContainer: {
    padding: "0 20px",
  },
  submitButtonContainer: {
    padding: "10px 20px",
    [theme.breakpoints.down(TABLET)]: {
      display: "none",
    },
  },
  next1ButtonContainer: {
    display: "none",
    [theme.breakpoints.down(TABLET)]: {
      display: "block",
    },
  },
}));
