import { Box, IconButton, ClickAwayListener } from "@material-ui/core";
import { CloseArrowIcon } from "../../../assets/icons";
import closeIcon from "../../../assets/images/overviewImages/Vector.png";

import { useStyles } from "./style";
const Info = ({ open, anchorEl, infoText, handleClose }) => {
  const classes = useStyles();
  const boundingVal = open ? anchorEl.getBoundingClientRect() : null;
  const top = 0;
  const left = open
    ? boundingVal.left - 380 > 0
      ? boundingVal.left - 380
      : 30
    : 0;

  const display = open ? "block" : "none";

  return (
    <Box
      boxShadow={5}
      className={classes.infoTextContainer}
      style={{ display, top, left }}
    >
      {display && (
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <div className={classes.closeIconButton}>
              <IconButton onClick={handleClose}>
                <img src={closeIcon} className={classes.imgIcon} />
              </IconButton>
            </div>
            <div className={classes.popOverContainer}>{infoText}</div>
            <div className={classes.closeArrow}>
              <IconButton onClick={handleClose} style={{ padding: 20 }}>
                <CloseArrowIcon />
              </IconButton>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default Info;
