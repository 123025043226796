import { ActiveDot, InactiveDot } from "../../assets/icons";
import { useStyles } from "./style";

const HederTitles = ["Form / Größe", "Farbe", "Form / Größe", "Geräte"];

const HeaderDot = ({ value }) => {
  const classes = useStyles();
  const getDots = () => new Array(6).fill(false).fill(true, 0, value);

  return (
    <div className={classes.mainContainer}>
      <div>
        <div
          style={{
            width: 80,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {getDots().map((val, indx) => (
            <div key={`${indx}`}>{val ? <ActiveDot /> : <InactiveDot />}</div>
          ))}
        </div>
      </div>
      <div className={classes.headerTitle}>{HederTitles[value - 1]}</div>
    </div>
  );
};

export default HeaderDot;
