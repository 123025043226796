import { createContext, useState } from "react";

export const ChooseShapeContext = createContext();

export const constant = {
  DOTS: "dots",
};

export const ShapeProvider = ({ children }) => {
  const defaultConfig = {
    [constant.DOTS]: 1,
  };

  const [config, setConfig] = useState(defaultConfig);

  const dispatch = (newVal) => {
    setConfig({
      ...config,
      ...newVal,
    });
  };

  return (
    <ChooseShapeContext.Provider value={{ config, dispatch }}>
      {children}
    </ChooseShapeContext.Provider>
  );
};
