import { makeStyles } from "@material-ui/core/styles";
import { TABLET } from "../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "none",
    [theme.breakpoints.down(TABLET)]: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 10,
    },
  },
  headerTitle: {
    marginTop: 10,
    color: "#383838",
  },
}));
