import { useContext } from "react";
import { Grid } from "@material-ui/core";
import Left from "./left";
import Right from "./right";
import MobileRight from "./mobileRight";
import { useStyles } from "./style";
import { productSliderData } from "../../api";
import { bodyColors } from "../../api/colors";
import { ChooseShapeContext, constant } from "../../context/ChooseShape";
import { submitSampleFreeOfCharge, submit3dOrderData } from "../../helper/api";
import { getColorName } from "../../helper/functions";

const Convince = ({
  selectedKitchenShape,
  selectedColor,
  selectedOverviewSlide,
  onChangeOverviewSlide,
  kitchenLengthValue,
  useOwnDevice,
  setUseOwnDevice,
  onSubmit,
  emailForm,
  setEmailForm,
  contactForm,
  setRoot,
  apiData,
  setContactForm,
}) => {
  const { config, dispatch } = useContext(ChooseShapeContext);

  const onSubmitSampleRequest = (contactFormData) => {
    const selectedSlider = productSliderData[selectedOverviewSlide].label;

    const selectedFront = getColorName(apiData, selectedColor.front, "front");
    const selectedBody = bodyColors[selectedColor.body].category;
    const selectedWorktop = getColorName(
      apiData,
      selectedColor.worktop,
      "worktop"
    );

    const submitData = {
      kitchen_length: kitchenLengthValue,
      device_type: useOwnDevice ? "Eigenes Gerät" : selectedSlider,
      kitchen_form: selectedKitchenShape.type,
      korpus_color: selectedBody,
      workplate_material_color: `${selectedWorktop.title} - ${selectedWorktop.color}`,
      front_material_color: `${selectedFront.title} - ${selectedFront.color}`,
      customer_tel: contactFormData.tel,
      customer_plz: contactFormData.plz,
      customer_address: contactFormData.address,
      customer_name: contactFormData.fullName,
      customer_email: contactFormData.mail,
      newsletter: contactFormData.useNewsLetter,
    };

    submitSampleFreeOfCharge(submitData).then((res) => {
      if (res.success) {
        setRoot();
      }
    });
  };

  const onSubmit3dOrder = (emailFormData) => {
    const selectedSlider = productSliderData[selectedOverviewSlide].label;

    const selectedFront = getColorName(apiData, selectedColor.front, "front");
    const selectedBody = bodyColors[selectedColor.body].category;
    const selectedWorktop = getColorName(
      apiData,
      selectedColor.worktop,
      "worktop"
    );

    const submitFormData = new FormData();
    submitFormData.append("customer_email", emailFormData.email);
    submitFormData.append("kitchen_length", kitchenLengthValue);
    submitFormData.append(
      "device_type",
      useOwnDevice ? "Eigenes Gerät" : selectedSlider
    );
    submitFormData.append("kitchen_form", selectedKitchenShape.type);
    submitFormData.append("korpus_color", selectedBody);
    submitFormData.append(
      "workplate_material_color",
      `${selectedWorktop.title} - ${selectedWorktop.color}`
    );
    submitFormData.append(
      "front_material_color",
      `${selectedFront.title} - ${selectedFront.color}`
    );
    submitFormData.append("kitchen_image", emailFormData.file);

    submit3dOrderData(submitFormData).then((res) => {
      if (res.success) {
        setRoot();
      }
    });
  };

  const classes = useStyles();
  return (
    <Grid container className={classes.mainContainer}>
      <Left
        selectedOverviewSlide={selectedOverviewSlide}
        onChangeOverviewSlide={onChangeOverviewSlide}
        selectedKitchenShape={selectedKitchenShape}
        kitchenLengthValue={kitchenLengthValue}
        useOwnDevice={useOwnDevice}
        setUseOwnDevice={setUseOwnDevice}
        onSubmit={onSubmit}
      />
      <Right
        emailForm={emailForm}
        setEmailForm={setEmailForm}
        contactForm={contactForm}
        setContactForm={setContactForm}
        onSubmitSampleRequest={onSubmitSampleRequest}
        onSubmit3dOrder={onSubmit3dOrder}
      />
      <MobileRight
        emailForm={emailForm}
        setEmailForm={setEmailForm}
        contactForm={contactForm}
        setContactForm={setContactForm}
        onSubmitSampleRequest={onSubmitSampleRequest}
        onSubmit3dOrder={onSubmit3dOrder}
      />
    </Grid>
  );
};

export default Convince;
