export const getColorName = (apiData, val, type) => {
  const subCategorySelected = Object.keys(val)[0];
  const colorSelected = Object.values(val)[0];
  const subCategory = apiData.find((obj) => obj.UUID === subCategorySelected);
  const subCategoryColor = subCategory.colors.find(
    (obj) => obj.UUID === colorSelected
  );

  return {
    color: subCategoryColor.title,
    title: subCategory.title,
    price: subCategory.price,
  };
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
