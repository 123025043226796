import { useStyles } from "./style";

const FrontColorSelect = ({ title, children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.title}>
          <span>{title}</span>
        </div>
        <div className={classes.childrenContainer}>{children}</div>
      </div>
    </>
  );
};

export default FrontColorSelect;
