import { IconButton } from "@material-ui/core";
import { useStyles } from "./style";

import CheckedIcon from "../../../assets/images/layouts/checked.png";
import UncheckedIcon from "../../../assets/images/layouts/unchecked.png";
const Checkbox = ({ label, active, onChange, isAgb }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.checkboxLabel}> {isAgb ? <a href="https://www.diekuechevomtischler.at/agb/" > {label} </a> : label} </div>
      <IconButton onClick={onChange}>
        <div className={classes.checkContainer}>
          <img src={active ? CheckedIcon : UncheckedIcon} alt={"Checkbox"} />
        </div>
      </IconButton>
    </div>
  );
};

export default Checkbox;
