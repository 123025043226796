import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FileIcon from "../../../assets/images/layouts/filePicker.png";
import SelectedFile from "../../../assets/images/layouts/selectedFile.png";

const useStyles = makeStyles((theme) => ({
  picker: {
    margin: "0 4%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  in: {
    position: "absolute",
    opacity: "0",
    margin: "1% -5%",
    padding: "1%",
  },
  img: {
    width: "5%",
    cursor: "pointer",
  },
  lbl: {
    fontWeight: "400",
    marginTop: 10,
    color: "#383838",
    fontSize: 20,
    marginLeft: "-4%",
  },
  selectedFile: {
    position: "absolute",
    height: 40,
    bottom: 15,
    left: 10,
  },
}));

const ImagePicker = ({ onChange, selected = false, icon = null }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.picker}>
        <input
          name="file-upload"
          id="file-upload"
          accept=".pdf,.png,.jpg"
          type="file"
          onChange={onChange}
          className={classes.in}
          // accept="image/png,image/jpg,image/jpeg"
        />
        <img
          src={icon === null ? FileIcon : icon}
          className={classes.img}
          alt="Shapes"
        />
        {selected && (
          <div>
            <img
              className={classes.selectedFile}
              alt="Shapes"
              src={SelectedFile}
            />
          </div>
        )}
        <label className={classes.lbl}>Datei wählen</label>
      </div>
    </>
  );
};

export default ImagePicker;
