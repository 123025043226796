import { makeStyles } from "@material-ui/core/styles";
import { TABLET } from "../../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  rootColorSelector: {
    display: "flex",
    flex: 1,
    [theme.breakpoints.down(TABLET)]: {
      display: "none",
    },
  },
  rightSideContainer: {
    padding: "10px 45px",
    [theme.breakpoints.down(TABLET)]: {
      display: "none",
    },
  },
  mobileRightSideContainer: {
    display: "none",
    [theme.breakpoints.down(TABLET)]: {
      padding: 10,
      display: "block",
    },
  },
  categoryTitle: {
    fontSize: "19px",
    fontWeight: "normal",
    color: "#4F4F4F",
    marginTop: "1%",
    marginBottom: "2%",
    [theme.breakpoints.down(TABLET)]: {
      textAlign: "center",
      margin: "10px 0",
    },
  },
  FrontColorSelectContainer: {
    padding: 10,
  },
  bodyGridMargin: {
    textAlign: "center",
    marginRight: "4.3%",
    [theme.breakpoints.down(TABLET)]: {
      marginRight: "1.4%",
    },
  },
  FrontImageContainer: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "8px 0",
  },
  BodyColorSelectContainer: {
    padding: 10,
  },
  BodyImageContainer: {
    // display: "flex",
    // flexDirection: "row",
    position: "relative",
    marginLeft: "-3%",
    margin: "8px 0",
  },
  WorktopColorSelectContainer: {
    padding: 10,
  },
  WorktopImageContainer: {
    marginTop: 13,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    justifyContent: "space-around",
    // marginLeft: "-3%",
    margin: "8px 0",
  },
  borderBottom: {
    paddingBottom: 20,
    borderBottom: "0.5px solid #4F4F4F",
  },
}));
