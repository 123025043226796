import { useState } from "react";
import { Grid, Box, IconButton } from "@material-ui/core";
import { useStyles } from "./style";
import { EuroSign, CloseArrowIcon } from "../../assets/icons";
import { bodyColors } from "../../api/colors";
import { productSliderData } from "../../api";

import InfoIcon from "../../assets/images/overviewImages/infoIcon.png";
import MobileButton from "../../components/UI/MobileButton";

import { getColorName } from "../../helper/functions";

const Right = ({
  onSubmit,
  kitchenLengthValue,
  selectedColor,
  selectedKitchenShape,
  selectedOverviewSlide,
  useOwnDevice,
  apiData,
  handleBookAppointment,
}) => {
  const classes = useStyles();
  const [showInfo, setShowInfo] = useState(null);

  const selectedFront = getColorName(apiData, selectedColor.front, "front");
  const selectedBody = bodyColors[selectedColor.body].category;
  const selectedWorktop = getColorName(
    apiData,
    selectedColor.worktop,
    "worktop"
  );
  const selectedSlider = productSliderData[selectedOverviewSlide].label;
  const selectedSliderPrice = productSliderData[selectedOverviewSlide].price;

  //Calculation for Selected Color and Device
  const getCalculation = (frontVal, worktopVal) => {
    // const subFrontCategorySelected = Object.keys(frontVal)[0];
    // const subWorktopCategorySelected = Object.keys(worktopVal)[0];

    // const subFrontCategory = apiData.find(
    //   (obj) => obj.UUID === subFrontCategorySelected
    // );
    // const subWorktopCategory = apiData.find(
    //   (obj) => obj.UUID === subWorktopCategorySelected
    // );

    const frontPrice = selectedFront.price;
    const worktopPrice = selectedWorktop.price;
    const finalPrice =
      frontPrice * kitchenLengthValue + worktopPrice * kitchenLengthValue;

    if (useOwnDevice) {
      return finalPrice;
    } else {
      return finalPrice + selectedSliderPrice;
    }
  };

  const calculatedAmount = getCalculation(
    selectedColor.front,
    selectedColor.worktop
  );
  const finalAmount = parseFloat(calculatedAmount / 1000).toFixed(3);

  const currentInfo =
    "Dieser Preis ist ein Richtwert - der genaue Preis ergibt sich bei einer Detailplanung mit unserem Tischler vor Ort. Solltest du jedoch keine groben Veränderungen vornehmen wollen , kannst du mit dem hier berechneten Preises rechnen.";

  return (
    <Grid xs={12} md={8}>
      <div className={classes.mobileRightContainer}>
        <div className={classes.mobileRightTitle}>Übersicht deiner Küche</div>
        <div className={classes.mobileTableContainer}>
          <div className={classes.mobileTableRow}>
            <div className={classes.mobileColumnCount}>
              <span className={classes.counterValue}>1</span>
            </div>
            <div className={classes.mobileTableColumn}>
              <div>Form / Gesamtlänge</div>
              <div>
                {selectedKitchenShape.type} / {kitchenLengthValue}m.
              </div>
            </div>
          </div>
          <div className={classes.mobileTableRow}>
            <div className={classes.mobileColumnCount}>
              <span className={classes.counterValue}>2</span>
            </div>
            <div className={classes.mobileTableColumn}>
              <div>Farbe</div>
              <div>
                Korpus - {selectedBody} <br></br>Front - {selectedFront.title}
                <br></br> ({selectedFront.color})
              </div>
            </div>
          </div>
          <div className={classes.mobileTableRow}>
            <div className={classes.mobileColumnCount}>
              <span className={classes.counterValue}>3</span>
            </div>
            <div className={classes.mobileTableColumn}>
              <div>Arbeitsplatte</div>
              <div>
                {selectedWorktop.title} <br></br>({selectedWorktop.color})
              </div>
            </div>
          </div>
          <div className={classes.mobileTableRow}>
            <div className={classes.mobileColumnCount}>
              <span className={classes.counterValue}>4</span>
            </div>
            <div className={classes.mobileTableColumn}>
              <div>Geräte</div>
              <div>
                {" "}
                {useOwnDevice && useOwnDevice
                  ? "Eigenes Gerät"
                  : `${selectedSlider}`}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.materialCostTitle}>
          Preis für deine Traumküche
        </div>
        <div className={classes.mobilePriceContainer}>
          <div>{""}</div>
          <div className={classes.priceText}>
            <span>{finalAmount}</span>
            <EuroSign />
          </div>
          <div>
            <IconButton onClick={() => setShowInfo(true)}>
              <img alt={"Info Icon"} src={InfoIcon} />
            </IconButton>
          </div>
        </div>
        <div className={classes.choiceYoursTitle}>
          So geht´s weiter - du hast die Wahl
        </div>
        <div className={classes.buttonContainer}>
          <MobileButton
            active
            onClick={handleBookAppointment}
            title="Termin buchen"
          />
          <MobileButton
            active
            onClick={() => onSubmit()}
            title="3D Entwurf & Muster"
          />
        </div>
        {showInfo !== null ? (
          <Box boxShadow={5} className={classes.infoContainer}>
            <div className={classes.activeLabelInfo}>{currentInfo}</div>
            <IconButton
              className={classes.closeArrowButton}
              onClick={() => setShowInfo(null)}
            >
              <div className={classes.closeArrowContainer}>
                <CloseArrowIcon />
              </div>
            </IconButton>
          </Box>
        ) : null}
      </div>
    </Grid>
  );
};

export default Right;
