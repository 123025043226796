import Header from "../components/Header/index";
import MaterialWrapper from "./materialStyle";

const HOC = ({ children, showBack, onClickBack, onClickMenu }) => {
  return (
    <MaterialWrapper>
      <Header
        showBack={showBack}
        onClickBack={onClickBack}
        onClickMenu={onClickMenu}
      />
      {children}
    </MaterialWrapper>
  );
};

export default HOC;
