import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  blackBtn: {
    width: "100%",
    color: "white",
    textTransform: "none",
    fontSize: 18,
    borderRadius: 30,
  },
  squareRadius: {
    borderRadius: 30,
  },
  roundRadius: {
    borderRadius: 30,
  },
  active: {
    background: "#383838",
    "&:hover": {
      backgroundColor: "#383838",
    },
  },
  inactive: {
    background: "gray",
    color: "#383838",
  },
}));

const Buttons = ({ children, active = false, onClick, round = false }) => {
  const classes = useStyles();

  const activeClass = active ? classes.active : classes.inactive;

  return (
    <Button
      onClick={onClick}
      className={`${classes.blackBtn} ${activeClass}`}
      variant="contained"
      disabled={!active}
    >
      {children}
    </Button>
  );
};

export default Buttons;
