import { Grid } from "@material-ui/core";
import Left from "./left";
import Right from "./right";
import { useStyles } from "./style";

const Overview = ({
  selectedKitchenShape,
  selectedOverviewSlide,
  onChangeOverviewSlide,
  kitchenLengthValue,
  selectedColor,
  useOwnDevice,
  setUseOwnDevice,
  apiData,
  onSubmit,
  handleBookAppointment,
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.mainContainer}>
      <Left
        selectedOverviewSlide={selectedOverviewSlide}
        onChangeOverviewSlide={onChangeOverviewSlide}
        selectedKitchenShape={selectedKitchenShape}
        selectedColor={selectedColor}
        kitchenLengthValue={kitchenLengthValue}
        useOwnDevice={useOwnDevice}
        setUseOwnDevice={setUseOwnDevice}
        onSubmit={onSubmit}
        apiData={apiData}
        handleBookAppointment={handleBookAppointment}
      />
      <Right
        selectedOverviewSlide={selectedOverviewSlide}
        selectedKitchenShape={selectedKitchenShape}
        selectedColor={selectedColor}
        kitchenLengthValue={kitchenLengthValue}
        useOwnDevice={useOwnDevice}
        onSubmit={onSubmit}
        apiData={apiData}
        handleBookAppointment={handleBookAppointment}
      />
    </Grid>
  );
};

export default Overview;
