import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TABLET } from "../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  imgContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    maxHeight: 340,
  },
  imgResponsive: {
    maxWidth: "100%",
    [theme.breakpoints.down(TABLET)]: {
      maxHeight: 230,
    },
  },
}));
const KitchenImage = ({ src, text }) => {
  const classes = useStyles();

  return (
    <div className={classes.imgContainer}>
      <img alt={text} src={src} className={classes.imgResponsive} />
    </div>
  );
};

export default KitchenImage;
