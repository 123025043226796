import { Grid } from "@material-ui/core";
import { useStyles } from "./style";
import { EuroSign } from "../../assets/icons";
import { bodyColors } from "../../api/colors";
import { productSliderData } from "../../api";
import Button from "../../components/UI/Button";
import { getColorName } from "../../helper/functions";

const Right = ({
  onSubmit,
  kitchenLengthValue,
  selectedColor,
  selectedKitchenShape,
  selectedOverviewSlide,
  apiData,
  useOwnDevice,
  handleBookAppointment,
}) => {
  const classes = useStyles();
  //To Get Selected Color Name
  const selectedFront = getColorName(apiData, selectedColor.front, "front");
  const selectedBody = bodyColors[selectedColor.body].category;
  const selectedWorktop = getColorName(
    apiData,
    selectedColor.worktop,
    "worktop"
  );
  const selectedSlider = productSliderData[selectedOverviewSlide].label;
  const selectedSliderPrice = productSliderData[selectedOverviewSlide].price;
  //Calculation for Selected Colors and Selected Device
  const getCalculation = (frontVal, worktopVal) => {
    const subFrontCategorySelected = Object.keys(frontVal)[0];
    const subWorktopCategorySelected = Object.keys(worktopVal)[0];

    const subFrontCategory = apiData.find(
      (obj) => obj.UUID === subFrontCategorySelected
    );
    const subWorktopCategory = apiData.find(
      (obj) => obj.UUID === subWorktopCategorySelected
    );

    const frontPrice = selectedFront.price;
    const worktopPrice = selectedWorktop.price;
    console.log("frontPrice", frontPrice, " worktopPrice", worktopPrice);

    const finalPrice =
      frontPrice * kitchenLengthValue + worktopPrice * kitchenLengthValue;
    if (useOwnDevice) {
      return finalPrice;
    } else {
      return finalPrice + selectedSliderPrice;
    }
  };

  const calculatedAmount = getCalculation(
    selectedColor.front,
    selectedColor.worktop
  );
  const finalAmount = parseFloat(calculatedAmount / 1000).toFixed(3);
  return (
    <Grid xs={12} md={8} className={classes.rootRight}>
      <div className={classes.rightContainer}>
        <div className={classes.rightTitle}>Übersicht deiner Küche</div>
        <div className={classes.tableContainer}>
          <div className={`${classes.tableRowContainer} ${classes.rowBorder}`}>
            <div className={classes.tableTitle}>Form / Gesamtlänge</div>
            <div className={classes.tableValue}>
              {selectedKitchenShape.type} / {kitchenLengthValue}m.
            </div>
          </div>
          <div className={`${classes.tableRowContainer} ${classes.rowBorder}`}>
            <div className={classes.tableTitle}>Farbe</div>
            <div className={classes.tableValue}>
              {/* Dynamic colors name */}
              Korpus - {selectedBody} &nbsp;&nbsp;&nbsp; Front -{" "}
              {selectedFront.title} &nbsp;({selectedFront.color})
            </div>
          </div>
          <div className={`${classes.tableRowContainer} ${classes.rowBorder}`}>
            <div className={classes.tableTitle}>Arbeitsplatte</div>
            <div className={classes.tableValue}>
              {selectedWorktop.title} &nbsp;({selectedWorktop.color})
            </div>
          </div>
          <div className={classes.tableRowContainer}>
            <div className={classes.tableTitle}>Geräte</div>
            <div className={classes.tableValue}>
              {useOwnDevice ? "Eigenes Gerät" : `${selectedSlider}`}
            </div>
          </div>
        </div>
        <div className={classes.materialCostTitle}>
          Preis für deine Traumküche
        </div>
        <div className={classes.priceDetailsContainer}>
          <div className={classes.priceContainer}>
            <span className={classes.priceText}>{finalAmount}</span>
            <EuroSign />
          </div>
          <div className={classes.priceInfoContainer}>
            Dieser Preis ist ein Richtwert - der genaue Preis ergibt sich bei
            einer Detailplanung mit unserem Tischler vor Ort. Solltest du jedoch
            keine groben Veränderungen vornehmen wollen , kannst du mit dem hier
            berechneten Preises rechnen.
          </div>
        </div>
        <div className={classes.choiceYoursTitle}>
          So geht´s weiter - du hast die Wahl
        </div>
        <div className={classes.buttonContainer}>
          <div className={classes.btnBox}>
            <Button active onClick={handleBookAppointment}>
              <span className={classes.btnNamePadding}>Termin buchen</span>
            </Button>
          </div>
          <div className={classes.btnBox}>
            <Button active onClick={() => onSubmit()}>
              <span className={classes.btnNamePadding}>
                3D Entwurf & Muster
              </span>
            </Button>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default Right;
