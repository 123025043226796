import { useContext } from "react";
import { ChooseShapeContext, constant } from "../../context/ChooseShape";
import { Grid } from "@material-ui/core";
import { useStyles } from "./style";
import { kitchenShape, HeaderAndDescription } from "../../api";
import ContainerWithTitleInfo from "../../components/ContainerWithTitleInfo";
import ChooseShape from "../../components/UI/ChooseShape";
import InputSlider from "../../components/UI/InputSlider";
import Button from "../../components/UI/Button";
import KitchenImage from "../../components/KitchenImage";
import ColorSelectorMobile from "./ColorSelector/mobile";
import HeaderDot from "../../components/HeaderDot";

const Left = ({
  selectedKitchenShape,
  kitchenLengthValue,
  setKitchenLengthValue,

  selectedColor,
  setSelectedColor,
  apiData,
  onSubmit,
  onSelectKitchenShape,
}) => {
  const classes = useStyles();

  const { config, dispatch } = useContext(ChooseShapeContext);
  //For the Mobile Screen nextButton Functions
  const nextButtonActive = () => {
    if (selectedKitchenShape) {
      if (kitchenLengthValue) {
        const activeFront = Object.keys(selectedColor.front).length > 0;
        const activeBody = selectedColor.body !== null;
        const activeWorktop = Object.keys(selectedColor.worktop).length > 0;

        if (activeFront && activeBody && activeWorktop) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const nextStep1ButtonActive = () => {
    if (selectedKitchenShape) {
      if (kitchenLengthValue) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const onClickNext = () => {
    dispatch({
      [constant.DOTS]: 2,
    });
  };

  const displayStep1 = config[constant.DOTS] === 1 ? "block" : "none";
  const displayStep2 =
    config[constant.DOTS] === 2 || config[constant.DOTS] === 3
      ? "block"
      : "none";

  return (
    <Grid xs={12} md={4} className={classes.leftContainer}>
      <HeaderDot value={config[constant.DOTS]} />

      <KitchenImage
        src={selectedKitchenShape.image}
        text={`${selectedKitchenShape.type}`}
      />
      <div style={{ display: displayStep1 }}>
        <ContainerWithTitleInfo
          title={HeaderAndDescription[0].title}
          infoText={HeaderAndDescription[0].description}
        >
          <Grid container className={classes.shapeContainer}>
            {kitchenShape.map((Obj, indx) => (
              <Grid item xs={3} key={`${indx}`}>
                <ChooseShape
                  selected={indx === selectedKitchenShape.index}
                  onSelect={() => onSelectKitchenShape(Obj)}
                >
                  <Obj.icon />
                </ChooseShape>
              </Grid>
            ))}
          </Grid>
        </ContainerWithTitleInfo>
        <ContainerWithTitleInfo
          title={HeaderAndDescription[1].title}
          infoText={HeaderAndDescription[1].description}
        >
          <div className={classes.sliderContainer}>
            <InputSlider
              kitchenLengthValue={kitchenLengthValue}
              setKitchenLengthValue={setKitchenLengthValue}
            />
          </div>
        </ContainerWithTitleInfo>
        <div className={classes.next1ButtonContainer}>
          <Button onClick={onClickNext} active={nextStep1ButtonActive()}>
            WEITER
          </Button>
        </div>
      </div>

      {apiData.length > 0 ? (
        <div style={{ display: displayStep2 }}>
          <ColorSelectorMobile
            selectedColor={selectedColor}
            apiData={apiData}
            setSelectedColor={setSelectedColor}
            onSubmit={onSubmit}
          />
        </div>
      ) : null}

      <div className={classes.submitButtonContainer}>
        <Button onClick={onSubmit} active={nextButtonActive()}>
          WEITER
        </Button>
      </div>
    </Grid>
  );
};

export default Left;
