import { useState } from "react";
import { Box, IconButton } from "@material-ui/core";
import InfoIcon from "../../../assets/images/overviewImages/infoIcon.png";
import SelectedInfoIcon from "../../../assets/images/overviewImages/selectedInfoIcon.png";
import { CloseArrowIcon } from "../../../assets/icons";
import { useStyles } from "./style";
import { productSliderData } from "../../../api";

const ProductBox = ({ label, productImage, active, onClick, onClickInfo }) => {
  const classes = useStyles();
  return (
    <div
      onClick={onClick}
      className={active ? classes.selectedSlide : classes.slide}
    >
      <img src={productImage} className={classes.productImage} alt={label} />
      <div className={active ? classes.selectedTitle : classes.title}>
        {label}
      </div>
      <IconButton>
        <img
          src={active ? SelectedInfoIcon : InfoIcon}
          className={classes.infoIcon}
          alt={`${label} info`}
          onClick={onClickInfo}
        />
      </IconButton>
    </div>
  );
};

const ProductSlider = ({ selectedOverviewSlide, onChangeOverviewSlide }) => {
  const classes = useStyles();
  const [showInfo, setShowInfo] = useState(null);

  const firstImg = productSliderData[0].image;
  const secondImg = productSliderData[1].image;
  const thirdImg = productSliderData[2].image;

  const firstLbl = productSliderData[0].label;
  const secondLbl = productSliderData[1].label;
  const thirdLbl = productSliderData[2].label;

  const currentLabel =
    showInfo !== null ? productSliderData[showInfo].label : "";
  const currentInfo = showInfo !== null ? productSliderData[showInfo].info : "";
  const currentImg = showInfo !== null ? productSliderData[showInfo].image : "";

  return (
    <div className={classes.rootContainer}>
      <div className={classes.mainSliderContainer}>
        <div className={classes.sliderContainer}>
          <ProductBox
            label={firstLbl}
            onClick={() => onChangeOverviewSlide(0)}
            active={selectedOverviewSlide === 0}
            productImage={firstImg}
            onClickInfo={() => setShowInfo(0)}
          />
          <ProductBox
            label={secondLbl}
            onClick={() => onChangeOverviewSlide(1)}
            active={selectedOverviewSlide === 1}
            productImage={secondImg}
            onClickInfo={() => setShowInfo(1)}
          />
          <ProductBox
            label={thirdLbl}
            onClick={() => onChangeOverviewSlide(2)}
            active={selectedOverviewSlide === 2}
            productImage={thirdImg}
            onClickInfo={() => setShowInfo(2)}
          />
        </div>
      </div>
      {showInfo !== null ? (
        <Box boxShadow={5} className={classes.infoContainer}>
          <img
            src={currentImg}
            className={classes.activeImageInfo}
            alt={currentLabel}
          />
          <div className={classes.activeLabelInfo}>{currentInfo}</div>
          <IconButton
            className={classes.closeArrowButton}
            onClick={() => setShowInfo(null)}
          >
            <div className={classes.closeArrowContainer}>
              <CloseArrowIcon />
            </div>
          </IconButton>
        </Box>
      ) : null}
    </div>
  );
};

export default ProductSlider;
