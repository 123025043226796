import { Grid } from "@material-ui/core";
import Left from "./left";
import ColorSelector from "./ColorSelector";
import { useStyles } from "./style";
import loaderImg from "../../assets/images/loader.gif";
const Home = ({
  selectedKitchenShape,
  marks,
  kitchenLengthValue,
  setKitchenLengthValue,
  selectedColor,
  setSelectedColor,
  onSubmit,
  apiData,
  onSelectKitchenShape,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Left
          selectedKitchenShape={selectedKitchenShape}
          onSelectKitchenShape={onSelectKitchenShape}
          marks={marks}
          selectedColor={selectedColor}
          kitchenLengthValue={kitchenLengthValue}
          setKitchenLengthValue={setKitchenLengthValue}
          setSelectedColor={setSelectedColor}
          onSubmit={onSubmit}
          apiData={apiData}
        />
        {apiData.length > 0 ? (
          <ColorSelector
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            apiData={apiData}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loaderImg} alt="Loading" />
          </div>
        )}
      </Grid>
    </>
  );
};

export default Home;
