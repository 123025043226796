import { useState } from "react";
import { IconButton } from "@material-ui/core";
import { InfoIcon } from "../../assets/icons";
import { useStyles } from "./style";
import Info from "./info";

const ContainerWithTitleInfo = ({ title, infoText, children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const handleClick = (event) => {
    setShowInfo(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowInfo(false);
  };

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.title}>{title}</div>
        <IconButton className={classes.InfoIcon} onClick={handleClick}>
          <InfoIcon />
        </IconButton>
        {showInfo && (
          <Info
            handleClose={handleClose}
            handleClick={handleClick}
            open={showInfo}
            infoText={infoText}
            anchorEl={anchorEl}
          />
        )}
      </div>
      {children}
    </>
  );
};

export default ContainerWithTitleInfo;
