import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
  },
  label: {
    margin: "5px 15px",
    color: "#383838",
  },
  input: {
    width: "100%",
    padding: 10,
    borderWidth: 1,
    borderRadius: 7,
  },
}));

const InputText = ({ label, name, value, onChange, onBlur }) => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.label}>{label}</div>
      <input
        className={classes.input}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default InputText;
