import Image1 from "../assets/images/colors/frontColors/Matt_lackiert/Matt_lackiert_1.png";
import Image2 from "../assets/images/colors/frontColors/Matt_lackiert/Matt_lackiert_2.png";
import Image3 from "../assets/images/colors/frontColors/Matt_lackiert/Matt_lackiert_3.png";
import Image4 from "../assets/images/colors/frontColors/Matt_lackiert/Matt_lackiert_4.png";
import Image5 from "../assets/images/colors/frontColors/Matt_lackiert/Matt_lackiert_5.png";
import Image6 from "../assets/images/colors/frontColors/Matt_lackiert/Matt_lackiert_6.png";
import Image7 from "../assets/images/colors/frontColors/Matt_lackiert/Matt_lackiert_7.png";
import Image8 from "../assets/images/colors/frontColors/Matt_lackiert/Matt_lackiert_8.png";

import Image9 from "../assets/images/colors/frontColors/Echtholz/Echtholz_1.png";
import Image10 from "../assets/images/colors/frontColors/Echtholz/Echtholz_2.png";
import Image11 from "../assets/images/colors/frontColors/Echtholz/Echtholz_3.png";
import Image12 from "../assets/images/colors/frontColors/Echtholz/Echtholz_4.png";
import Image13 from "../assets/images/colors/frontColors/Echtholz/Echtholz_5.png";
import Image14 from "../assets/images/colors/frontColors/Echtholz/Echtholz_6.png";
import Image15 from "../assets/images/colors/frontColors/Echtholz/Echtholz_7.png";
import Image16 from "../assets/images/colors/frontColors/Echtholz/Echtholz_8.png";

import Image17 from "../assets/images/colors/bodyColors/White.png";
import Image18 from "../assets/images/colors/bodyColors/Graphite.png";

import Image19 from "../assets/images/colors/worktop/Dekor/Dekor_1.png";
import Image20 from "../assets/images/colors/worktop/Dekor/Dekor_2.png";
import Image21 from "../assets/images/colors/worktop/Dekor/Dekor_3.png";
import Image22 from "../assets/images/colors/worktop/Dekor/Dekor_4.png";

import Image23 from "../assets/images/colors/worktop/Holz/Holz_1.png";
import Image24 from "../assets/images/colors/worktop/Holz/Holz_2.png";
import Image25 from "../assets/images/colors/worktop/Holz/Holz_3.png";
import Image26 from "../assets/images/colors/worktop/Holz/Holz_4.png";

import Image27 from "../assets/images/colors/worktop/Stein/Stein_1.png";
import Image28 from "../assets/images/colors/worktop/Stein/Stein_2.png";
import Image29 from "../assets/images/colors/worktop/Stein/Stein_3.png";
import Image30 from "../assets/images/colors/worktop/Stein/Stein_4.png";

import Image31 from "../assets/images/colors/worktop/Uni/Uni_1.png";
import Image32 from "../assets/images/colors/worktop/Uni/Uni_2.png";
import Image33 from "../assets/images/colors/worktop/Uni/Uni_3.png";
import Image34 from "../assets/images/colors/worktop/Uni/Uni_4.png";

export const mattLackiert = {
  title: "Matt lackiert",
  images: [
    { id: 1, image: Image1, colorName: "Grey" },
    { id: 2, image: Image2, colorName: "Black" },
    { id: 3, image: Image3, colorName: "Solid Black" },
    { id: 4, image: Image4, colorName: "Dark Grey" },
    { id: 5, image: Image5, colorName: "White" },
    { id: 6, image: Image6, colorName: "Pearl" },
    { id: 7, image: Image7, colorName: "Cement" },
    { id: 8, image: Image8, colorName: "Cream" },
  ],
  price: 1000,
};
export const HochglanzLackiert = {
  title: "Hochglanz lackiert ",
  images: [
    { id: 9, image: Image9, colorName: "Pink" },
    { id: 10, image: Image10, colorName: "Grey" },
    { id: 11, image: Image11, colorName: "Wood" },
    { id: 12, image: Image12, colorName: "Dark Wood" },
    { id: 13, image: Image13, colorName: "White" },
    { id: 14, image: Image14, colorName: "Off White" },
    { id: 15, image: Image15, colorName: "Light Sky" },
    { id: 16, image: Image16, colorName: "Grey" },
  ],
  price: 1000,
};
export const echtholz = {
  title: "Echtholz",
  images: [
    { id: 9, image: Image9, colorName: "Pink" },
    { id: 10, image: Image10, colorName: "Grey" },
    { id: 11, image: Image11, colorName: "Wood" },
    { id: 12, image: Image12, colorName: "Dark Wood" },
    { id: 13, image: Image13, colorName: "White" },
    { id: 14, image: Image14, colorName: "Off White" },
    { id: 15, image: Image15, colorName: "Light Sky" },
    { id: 16, image: Image16, colorName: "Grey" },
  ],
  price: 1200,
};

export const spachteltechnik = {
  title: "Spachteltechnik",
  images: [
    { id: 9, image: Image9, colorName: "Pink" },
    { id: 10, image: Image10, colorName: "Grey" },
    { id: 11, image: Image11, colorName: "Wood" },
    { id: 12, image: Image12, colorName: "Dark Wood" },
    { id: 13, image: Image13, colorName: "White" },
    { id: 14, image: Image14, colorName: "Off White" },
    { id: 15, image: Image15, colorName: "Light Sky" },
    { id: 16, image: Image16, colorName: "Grey" },
  ],
  price: 1400,
};

export const frontColors = [
  mattLackiert,
  HochglanzLackiert,
  echtholz,
  spachteltechnik,
];

export const bodyColors = [
  {
    category: "Weiß",
    image: [{ id: 17, image: Image17, colorName: "Weiß" }],
  },
  {
    category: "graphite",
    image: [{ id: 18, image: Image18, colorName: "Black" }],
  },
];

export const worktop = [
  {
    category: "Dekor",
    image: [
      { id: 19, image: Image19, colorName: "Grey" },
      { id: 20, image: Image20, colorName: "Grey" },
      { id: 21, image: Image21, colorName: "Grey" },
      { id: 22, image: Image22, colorName: "Grey" },
    ],
    price: 150,
  },
  {
    category: "Stein",
    image: [
      { id: 27, image: Image27, colorName: "Grey" },
      { id: 28, image: Image28, colorName: "Grey" },
      { id: 29, image: Image29, colorName: "Grey" },
      { id: 30, image: Image30, colorName: "Grey" },
      // Image27, Image28, Image29, Image30
    ],
    price: 1400,
  },
  {
    category: "Holz",
    image: [
      { id: 23, image: Image23, colorName: "Grey" },
      { id: 24, image: Image24, colorName: "Grey" },
      { id: 25, image: Image25, colorName: "Grey" },
      { id: 26, image: Image26, colorName: "Grey" },
    ],
    price: 1500,
  },

  {
    category: "Uni",
    image: [
      { id: 31, image: Image31, colorName: "Grey" },
      { id: 32, image: Image32, colorName: "Grey" },
      { id: 33, image: Image33, colorName: "Grey" },
      { id: 34, image: Image34, colorName: "Grey" },
    ],
    price: 1600,
  },
];

export const Granit = {
  title: "Granit",
  image: [
    { id: 19, image: Image19, colorName: "Grey" },
    { id: 20, image: Image20, colorName: "Grey" },
    { id: 21, image: Image21, colorName: "Grey" },
    { id: 22, image: Image22, colorName: "Grey" },
  ],
  price: 1000,
};

export const Marmor = {
  title: "Marmor",
  image: [
    { id: 27, image: Image27, colorName: "Grey" },
    { id: 28, image: Image28, colorName: "Grey" },
    { id: 29, image: Image29, colorName: "Grey" },
    { id: 30, image: Image30, colorName: "Grey" },
  ],
  price: 1800,
};

export const Compact = {
  title: "Compact",
  image: [
    { id: 23, image: Image23, colorName: "Grey" },
    { id: 24, image: Image24, colorName: "Grey" },
    { id: 25, image: Image25, colorName: "Grey" },
    { id: 26, image: Image26, colorName: "Grey" },
  ],
  price: 350,
};

export const Dekor = {
  title: "Dekor",
  image: [
    { id: 31, image: Image31, colorName: "Grey" },
    { id: 32, image: Image32, colorName: "Grey" },
    { id: 33, image: Image33, colorName: "Grey" },
    { id: 34, image: Image34, colorName: "Grey" },
  ],
  price: 150,
};
export const worktopColors = [Granit, Marmor, Compact, Dekor];
