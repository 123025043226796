import { MOBILE_SCREEN, TABLET, MID_SCREEN } from "../../config/styleConfig";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    position: "relative",
  },
  title: {
    fontSize: "23px",
    marginTop: 15,
    fontWeight: 700,
    color: "#383838",
  },
  InfoIcon: {
    marginTop: "1.2%",
  },
  infoBox: {
    position: "absolute",
    top: 0,
    right: 50,
    left: 50,
    backgroundColor: "#FFF",
    zIndex: 10,
    fontSize: 20,
    boxShadow: "0 4px 16px 0px #aeaeae",
  },
  infoText: {
    margin: 70,
    fontSize: 16,
    fontWeight: 400,
  },

  closeIconButton: {
    position: "absolute",
    right: -5,
    top: 0,
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      display: "none",
    },
  },
  popOverContainer: {
    width: 335,
    padding: "50px 30px",
    fontSize: 16,
    color: "#383838",
    lineHeight: 1.65,
    textAlign: "center",
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      width: "unset",
    },
  },
  infoTextContainer: {
    position: "absolute",
    left: "0 !important",
    zIndex: 1000,
    backgroundColor: "#FFFFFF",

    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      left: "0 !important",
      right: 0,
      bottom: 0,
      top: "50% !important",
      position: "fixed",
    },
    [theme.breakpoints.only(TABLET)]: {},
    [theme.breakpoints.only(MID_SCREEN)]: {
      left: "20px !important",
    },
  },
  closeArrow: {
    display: "none",
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
  },
  infoContainer: {
    position: "relative",
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      position: "unset",
    },
  },
}));
