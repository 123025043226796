import { makeStyles } from "@material-ui/core/styles";
import { TABLET } from "../../../config/styleConfig";

const useStyles = makeStyles((theme) => ({
  blackBtn: {
    width: "100%",
    color: "white",
    textTransform: "none",
    fontSize: 18,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  squareRadius: {
    borderRadius: 30,
  },
  roundRadius: {
    borderRadius: 30,
  },
  active: {
    background: "#383838",
    "&:hover": {
      backgroundColor: "#383838",
    },
  },
  inactive: {
    background: "gray",
    color: "#383838",
  },
  btnBox: {
    margin: "0 20px",
    display: "flex",
    height: "40px",
    background: "#383838",
    padding: "0 20px",
    borderRadius: 30,
    [theme.breakpoints.down(TABLET)]: {
      margin: "0 5px",
      padding: "0 5px",
      width: "100%",
    },
  },
  mobileBtnText: {
    fontSize: 12,
  },
}));

const MobileButton = ({ active = false, onClick, round = false, title }) => {
  const classes = useStyles();

  const activeClass = active ? classes.active : classes.inactive;

  return (
    <div className={classes.btnBox}>
      <div
        onClick={onClick}
        className={`${classes.blackBtn} ${activeClass}`}
        variant="contained"
        disabled={!active}
      >
        <div className={classes.mobileBtnText}>{title}</div>
      </div>
    </div>
  );
};

export default MobileButton;
