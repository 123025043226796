import { useState, useContext } from "react";

import HOC from "./HOC";
import HOME from "./screens/Home";
import Overview from "./screens/Overview";
import Convince from "./screens/Convince";
import { ChooseShapeContext, constant } from "./context/ChooseShape";
import { DataFetchContext } from "./context/DataFetchApi";
// import result from "./context/DataFetchApi";
import { kitchenShape, lengthSliderMarks } from "./api";

const App = () => {
  const displayDefault = {
    home: "block",
    overview: "none",
    convince: "none",
  };
  const [display, setDisplay] = useState(displayDefault);

  const { config, dispatch } = useContext(ChooseShapeContext);
  const { apiData } = useContext(DataFetchContext);

  const showBackButton = () => {
    if (display.home === "block") {
      if (config[constant.DOTS] === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  /**
   * HOME PAGE DATA START
   */

  const [selectedKitchenShape, setSelectedKitchenShape] = useState(
    kitchenShape[0]
  );

  const [kitchenLengthValue, setKitchenLengthValue] = useState(
    lengthSliderMarks[0].value
  );

  const defaultColors = {
    front: {},
    body: null,
    worktop: {},
  };

  const [selectedColor, setSelectedColor] = useState(defaultColors);
  const onSelectKitchenShape = (data) => {
    setSelectedKitchenShape(data);
  };

  /**
   * HOME PAGE DATA END
   */

  /**
   * Overview DATA START
   */
  const [selectedOverviewSlide, setSelectedOverviewSlide] = useState(1);

  const [useOwnDevice, setUseOwnDevice] = useState(false);

  const onChangeOverviewSlide = (val) => {
    setSelectedOverviewSlide(val);
  };

  const handleBookAppointment = () => {
    window.open("https://app.cituro.com/booking/2337263#step=1");
  };
  /**
   * Overview DATA END
   */

  /**
   * Convince DATA START
   */
  const defaultEmailForm = {
    email: "",
    file: null,
  };
  const defaultContactForm = {
    fullName: "",
    address: "",
    tel: "",
    mail: "",
    plz: "",
  };
  const [emailForm, setEmailForm] = useState(defaultEmailForm);
  const [contactForm, setContactForm] = useState(defaultContactForm);
  /**
   * Convince DATA END
   */

  const show = (showKey) => {
    setDisplay({
      home: "none",
      overview: "none",
      convince: "none",
      [showKey]: "block",
    });
    if (showKey === "overview") {
      dispatch({
        [constant.DOTS]: 4,
      });
    } else if (showKey === "convince") {
      dispatch({
        [constant.DOTS]: 6,
      });
    }
  };

  const onFinalSubmit = () => {
    alert("Submitted");
  };

  const setRoot = () => {
    show("home");
    dispatch({
      [constant.DOTS]: 1,
    });
  };

  const onClickBack = (deductVal = null) => {
    const minusVal = deductVal ? deductVal : 1;

    if (display.home === "block") {
      dispatch({
        [constant.DOTS]: config[constant.DOTS] - minusVal,
      });
    } else if (display.overview === "block") {
      if (config[constant.DOTS] === 4) {
        show("home");
        dispatch({
          [constant.DOTS]: deductVal ? config[constant.DOTS] - minusVal : 3,
        });
      } else {
        show("overview");
        dispatch({
          [constant.DOTS]: config[constant.DOTS] - minusVal,
        });
      }
    } else if (display.convince === "block") {
      show("overview");
      dispatch({
        [constant.DOTS]: deductVal ? config[constant.DOTS] - minusVal : 4,
      });
    }
  };

  const onClickMenu = () => {
    alert("On Click Menu");
  };

  return (
    <>
      <HOC
        showBack={showBackButton()}
        onClickBack={onClickBack}
        onClickMenu={onClickMenu}
      >
        <div style={{ display: display.home }}>
          <HOME
            selectedKitchenShape={selectedKitchenShape}
            onSelectKitchenShape={onSelectKitchenShape}
            marks={lengthSliderMarks}
            kitchenLengthValue={kitchenLengthValue}
            setKitchenLengthValue={setKitchenLengthValue}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            apiData={apiData}
            onSubmit={() => show("overview")}
          />
        </div>
        {display.overview === "block" && (
          <div style={{ display: display.overview }}>
            <Overview
              selectedKitchenShape={selectedKitchenShape}
              kitchenLengthValue={kitchenLengthValue}
              selectedOverviewSlide={selectedOverviewSlide}
              onChangeOverviewSlide={onChangeOverviewSlide}
              selectedColor={selectedColor}
              useOwnDevice={useOwnDevice}
              setUseOwnDevice={setUseOwnDevice}
              onSubmit={() => show("convince")}
              apiData={apiData}
              handleBookAppointment={handleBookAppointment}
            />
          </div>
        )}
        {display.convince === "block" && (
          <div style={{ display: display.convince }}>
            <Convince
              selectedKitchenShape={selectedKitchenShape}
              kitchenLengthValue={kitchenLengthValue}
              selectedOverviewSlide={selectedOverviewSlide}
              onChangeOverviewSlide={onChangeOverviewSlide}
              useOwnDevice={useOwnDevice}
              setUseOwnDevice={setUseOwnDevice}
              emailForm={emailForm}
              selectedColor={selectedColor}
              setEmailForm={setEmailForm}
              contactForm={contactForm}
              setContactForm={setContactForm}
              apiData={apiData}
              setRoot={setRoot}
              onSubmit={onFinalSubmit}
            />
          </div>
        )}
      </HOC>
    </>
  );
};

export default App;
