import { makeStyles } from "@material-ui/core/styles";
import { MID_SCREEN, TABLET, MOBILE_SCREEN } from "../../../config/styleConfig";

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: "block",
    [theme.breakpoints.down(TABLET)]: {
      display: "none",
    },
    position: "relative",
  },
  mainSliderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  prevIcon: {
    transform: "translateY(-45px)",
    [theme.breakpoints.only(MID_SCREEN)]: {
      transform: "translateY(-15px)",
    },
  },
  nextIcon: {
    transform: "translateY(-45px)",
    [theme.breakpoints.only(MID_SCREEN)]: {
      transform: "translateY(-15px)",
    },
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 15,
    flex: 1,
    justifyContent: "space-between",
  },
  slide: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  title: {
    position: "absolute",
    fontSize: 18,
    color: "#383838",
    marginTop: 10,
    bottom: -40,
    [theme.breakpoints.only(MID_SCREEN)]: {
      bottom: -25,
      fontSize: 14,
    },
  },
  selectedTitle: {
    position: "absolute",
    fontSize: 30,
    color: "#383838",
    fontWeight: 700,
    bottom: -40,
    [theme.breakpoints.only(MID_SCREEN)]: {
      bottom: -25,
      fontSize: 14,
    },
  },
  currentInfo: {
    maxWidth: "50%",
    margin: "auto",
    textAlign: "center",
    marginTop: 30,
  },
  productImage: {
    width: 40,
    [theme.breakpoints.only(MID_SCREEN)]: {
      width: 30,
    },
  },
  selectedProductImage: {
    width: 120,
    [theme.breakpoints.only(MID_SCREEN)]: {
      width: 80,
    },
  },
  popOverContainer: {
    width: 320,
    padding: "50px 30px",
    fontSize: 16,
    color: "#383838",
    textAlign: "center",
    lineHeight: 1.65,
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      width: "unset",
    },
  },
  infoTextContainer: {
    position: "absolute",
    left: "8px !important",
    top: "135px !important",
    zIndex: 1000,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      left: "0 !important",
      right: 0,
      bottom: 0,
      top: "50% !important",
      position: "fixed",
    },
    [theme.breakpoints.only(TABLET)]: {},
    [theme.breakpoints.only(MID_SCREEN)]: {
      left: "20px !important",
    },
  },
  closeArrow: {
    display: "none",
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
  },
  infoContainer: {
    position: "relative",
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      position: "unset",
    },
  },
  InfoIcon: {
    marginTop: "0%",
  },
  closeIconButton: {
    position: "absolute",
    right: -5,
    top: 0,
    [theme.breakpoints.only(MOBILE_SCREEN)]: {
      display: "none",
    },
  },
  imgIcon: {
    height: 35,
  },
}));
