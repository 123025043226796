import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkboxLabel: {
    fontWeight: 700,
    color: "#383838",
    fontSize: 16,
    marginTop: -10,
  },
  checkContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));
