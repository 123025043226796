import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const Customlider = withStyles({
  root: {
    color: "#383838",
    height: 2,
    padding: "30px 0",
  },
  thumb: {
    height: 14,
    width: 5,
    color: "#383838",
    backgroundColor: "#000",
    boxShadow: "red",
    marginTop: -4,
    marginLeft: 0,
    borderRadius: 0,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: "red",
      },
    },
  },
  active: {},
  valueLabel: {
    top: -22,
    "& *": {
      background: "transparent",
      color: "#383838",
    },
  },
  track: {
    height: 5,
  },
  rail: {
    height: 5,
    opacity: 0.5,
    backgroundColor: "#C4C4C4",
  },
  mark: {
    width: "unset",
    height: "unset",
  },
  markLabel: {
    marginTop: "15px",
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

export default Customlider;
